import React, { useEffect, useState } from "react";

import "./support.scss";
import { CreateSupportTicketModal } from "../../../Modals";
import { useStateValue } from "../../../StateProvider";
import { GenerateRefreshToken, GetSupportTickets } from "../../../apiCall";
import { toast } from "react-toastify";

// const Tickets = [
//     {
//         id: 1,
//         time: "22 July 2023",
//         description:
//             "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//         status: "sent",
//     },
//     {
//         id: 2,
//         time: "22 July 2023",
//         description:
//             "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//         status: "completed",
//     },
//     {
//         id: 3,
//         time: "22 July 2023",
//         description:
//             "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//         status: "sent",
//     },
//     {
//         id: 4,
//         time: "22 July 2023",
//         description:
//             "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//         status: "completed",
//     },
// ];
const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
const Support = () => {
    const [, dispatch] = useStateValue()
    const showCreateSupportTicketModal = () => {
        dispatch({
            type: "SET_CREATE_SUPPORT_TICKET_MODAL_SHOW",
            status: true
        })
    }

    const [tickets, setTickets] = useState([])
    useEffect(() => {
        const getSupportTickets = async () => {
            const Re = await GetSupportTickets()
            // console.log(Re)
            if(Re?.data?.status[0].Error === "False"){
                setTickets(Re.data.value)
            }else{
                if(Re?.data?.status){
                    toast.error(Re?.data?.status[0].ResponseMessage)
                }else{
                    if (
                        Re?.response?.data?.detail ===
                        "Unauthenticated User, Please login first."
                    ) {
                        const refreshRe = await GenerateRefreshToken();
    
                        console.log(refreshRe, "REFRESH RE");
    
                        if (refreshRe?.data?.access_token) {
                            localStorage.setItem(
                                "refresh_token",
                                refreshRe?.data?.refresh_token
                            );
                            localStorage.setItem(
                                "token",
                                refreshRe?.data?.access_token
                            );
                            getSupportTickets();
                        }else{
                            localStorage.clear()
                            dispatch({
                                type: "SET_USER_LOGGED_IN",
                                status: false,
                              });
                        }
    
                    } else {
                        toast.error("Something went wrong, Please try agian.");
                    }
                }
            }
        }
        getSupportTickets()
    }, [dispatch])
    return (
        <div className='support-main'>
            <CreateSupportTicketModal setTickets={setTickets}/>
            <button className='primary' onClick={showCreateSupportTicketModal}>Create Support Ticket</button>

            {tickets.length > 0 ? tickets.map((ticket) => (
                <div className='ticket' key={ticket.id}>
                    <div className='time-status'>
                        <span className='time'>{`${new Date(ticket.create_at)
                                            .toLocaleString()
                                            .slice(0, 2)} ${
                                            MONTHS[
                                                new Date(
                                                    ticket.create_at
                                                ).getMonth()
                                            ]
                                        } ${new Date(
                                            ticket.create_at
                                        ).getFullYear()}`}</span>
                        <span className={`status ${ticket.status}`}>
                            {ticket.status}
                        </span>
                    </div>
                    <p>{ticket.problem}</p>
                </div>
            )) : <h2 className="empty">You don't have any support ticket yet.</h2>}
        </div>
    );
};

export default Support;
