import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
} from "react-share";

// import CaseStudyImg from "../../Assets/images/case-study-details.png";
// import { ReactComponent as Insta } from "../../Assets/icons/instagram.svg";
import { ReactComponent as Facebook } from "../../Assets/icons/facebook.svg";
import { ReactComponent as LinkedIn } from "../../Assets/icons/linkedIn.svg";
import { ReactComponent as Twitter } from "../../Assets/icons/twitter.svg";
import { ReactComponent as WhatsApp } from "../../Assets/icons/whatsapp.svg";
import Copy from "../../Assets/icons/copy.svg";
import "./caseStudyDetails.scss";
import { GetCaseStudyById } from "../../apiCall";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { CACHE_TIME, domainName } from "../../Constants";

const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const CaseStudyDetails = () => {
    const { id } = useParams();
    const [caseStudy, setCaseStudyDetails] = useState();
    useQuery(["product-details"], () => GetCaseStudyById(id), {
        // staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onSuccess: (data) => {
            // console.log(data);
            if (data.data?.status[0].Error === "False") {
                setCaseStudyDetails(data.data.value);
            }
        },
        onError: (err) => {
            // console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return caseStudy ? (
        <div className='case-study-main'>
            <div className='section-1'>
                {/* <p>{caseStudy?.title}</p> */}
                <h1>{caseStudy?.title}</h1>
                <p className='description'>{caseStudy?.description}</p>
            </div>
            <div className='section-2'>
                <div className='part-1'>
                    {/* <div className='text-container'>
                        <h2>Challenge</h2>
                        <p>{caseStudy.challenges}</p>
                    </div>
                    <div className='text-container'>
                        <h2>Solution</h2>
                        <p>{caseStudy.solution}</p>
                    </div>
                    <div className='text-container'>
                        <h2>Results</h2>
                        <p>{caseStudy.results}</p>
                    </div> */}
                    {caseStudy?.casestudy_data?.map((data, indx) => (
                        <div
                            className='text-container'
                            key={"case_study_data" + indx}>
                            <h2>{data.title}</h2>
                            {data.description?.length > 1 ? (
                                <ul>
                                    {data.description.map((desc, indx) => (
                                        <li
                                            key={
                                                "case_study_data__desc" + indx
                                            }>
                                            {desc}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{data.description}</p>
                            )}
                        </div>
                    ))}
                </div>
                <div className='part-2'>
                    <img
                        src={domainName + caseStudy?.image}
                        className='case-study-img'
                        alt=''
                    />
                    <div className='details'>
                        {/* <span>
                                <p className='title'>Written by</p>
                                <p className='description'>
                                    {caseStudy?.author}
                                </p>
                            </span> */}
                        <span>
                            <p className='title'>Published On</p>
                            <p className='description'>{`${new Date(
                                caseStudy?.published_date
                            )
                                .toLocaleString()
                                .slice(0, 2)} ${
                                MONTHS[
                                    new Date(
                                        caseStudy?.published_date
                                    ).getMonth()
                                ]
                            } ${new Date(
                                caseStudy?.published_date
                            ).getFullYear()}`}</p>
                        </span>
                        <span className='social'>
                            <div className='logos'>
                                {/* <span className='insta'>{<Insta />}</span> */}
                                <FacebookShareButton url={window.location.href}>
                                    <span className='facebook'>
                                        {<Facebook />}
                                    </span>
                                </FacebookShareButton>
                                <LinkedinShareButton url={window.location.href}>
                                    <span className='linkedin'>
                                        {<LinkedIn />}
                                    </span>
                                </LinkedinShareButton>
                                <TwitterShareButton url={window.location.href}>
                                    <span className='twitter'>
                                        {<Twitter />}
                                    </span>
                                </TwitterShareButton>
                                <WhatsappShareButton url={window.location.href}>
                                    <span className='whatsapp'>
                                        {<WhatsApp />}
                                    </span>
                                </WhatsappShareButton>
                            </div>
                            <span
                                className='copy'
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        window.location.href
                                    );
                                    toast.success("Link copied");
                                }}>
                                <img src={Copy} alt='' />
                                <p>Copy link</p>
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default CaseStudyDetails;
