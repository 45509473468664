import React, { useEffect, useRef, useState } from "react";

import "./myDashboard.scss";
import Gmeet from "../../../Assets/icons/gmeet.svg";
// import { MeetingDetailsModal } from "../../../Modals";
import { ReactComponent as Arrow } from "../../../Assets/icons/arrow.svg";
import { ReactComponent as Correct } from "../../../Assets/icons/correct.svg";
import { ReactComponent as Export } from "../../../Assets/icons/log-out.svg";
import { GenerateRefreshToken, GetActiveServices, GetInquiryDetails } from "../../../apiCall";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import { ExportPreviewModal } from "../../../Modals";
import { useStateValue } from "../../../StateProvider";

// const Meetings = [
//     {
//         id: 1,
//         meeting_name: "India Company Registration",
//         host: "Nisarg Trivedi",
//         time: "Tuesday, 06 May Time: 2:00 PM",
//         platform: "Google Meet",
//         link: "https://meet.google.com/XX-XXX-XXX",
//     },
//     {
//         id: 2,
//         meeting_name: "India Company Registration",
//         host: "Nisarg Trivedi",
//         time: "Tuesday, 06 May Time: 2:00 PM",
//         platform: "Google Meet",
//         link: "https://meet.google.com/XX-XXX-XXX",
//     },
//     {
//         id: 3,
//         meeting_name: "India Company Registration",
//         host: "Nisarg Trivedi",
//         time: "Tuesday, 06 May Time: 2:00 PM",
//         platform: "Google Meet",
//         link: "https://meet.google.com/XX-XXX-XXX",
//     },
// ];
const DAYS = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
// const Progress = [
//     {
//         id: 1,
//         title: "India Company Registration",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed sed u",
//         start_date: "Tuesday, 06 May 2023",
//         end_date: "Tuesday, 25 May 2023",
//         progress: 70,
//         last_update: "Tuesday, 08 May 2023",
//         updates: [
//             {
//                 id: "update1",
//                 title: "Wire work started",
//                 date: "Tuesday, 06 May 2023",
//                 note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed sed u",
//             },
//             {
//                 id: "update2",
//                 title: "Wire work started",
//                 date: "Tuesday, 06 May 2023",
//                 note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed sed u",
//             },
//         ],
//     },
//     {
//         id: 1,
//         title: "India Company Registration",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed sed u",
//         start_date: "Tuesday, 06 May 2023",
//         end_date: "Tuesday, 25 May 2023",
//         progress: 70,
//         last_update: "Tuesday, 08 May 2023",
//         updates: [
//             {
//                 title: "Wire work started",
//                 date: "Tuesday, 06 May 2023",
//                 note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed sed u",
//             },
//             {
//                 title: "Wire work started",
//                 date: "Tuesday, 06 May 2023",
//                 note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed sed u",
//             },
//         ],
//     },
// ];
const MyDashboard = () => {
    const progressRef = useRef();
    const [meetings, setMeetings] = useState();
    const [activeServices, setActiveServices] = useState();
    const [categories, setCategories] = useState();
    const [selectedCategory, setSelectedCategory] = useState("");
    const [pdfData, setPdfData] = useState();
    const [, dispatch] = useStateValue()
    const handleDownloadPdf = async () => {
        const element = progressRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL("image/png");
        // console.log(data);
        setPdfData(data);

        dispatch({
            type: "SET_EXPORT_PREVIEW_MODAL_SHOW",
            status: true,
        });
    };

    useEffect(() => {
        const getMeetingDetails = async () => {
            const Re = await GetInquiryDetails();
            // console.log(Re);
            if (Re.data?.status[0].Error === "False") {
                setMeetings(Re.data.value);
            } else {
                if (Re.data?.status) {
                    toast.error(Re.data.status[0].ResponseMessage);
                } else {
                    if (
                        Re?.response?.data?.detail ===
                        "Unauthenticated User, Please login first."
                    ) {
                        const refreshRe = await GenerateRefreshToken();

                        console.log(refreshRe, "REFRESH RE");

                        if (refreshRe?.data?.access_token) {
                            localStorage.setItem(
                                "refresh_token",
                                refreshRe?.data?.refresh_token
                            );
                            localStorage.setItem(
                                "token",
                                refreshRe?.data?.access_token
                            );
                            getMeetingDetails();
                        }else{
                            localStorage.clear()
                            dispatch({
                                type: "SET_USER_LOGGED_IN",
                                status: false,
                              });
                        }

                    } else {
                        toast.error("Something went wrong, Please try agian.");
                    }
                }
            }
        };
        const getActiveServices = async () => {
            const Re = await GetActiveServices();
            // console.log(Re);
            if (Re.data?.status[0].Error === "False") {
                setActiveServices(Re.data.value.services);
                setCategories(Re.data.value.categories);
            } else {
                if (Re.data?.status) {
                    toast.error(Re.data.status[0].ResponseMessage);
                } else {
                    if (
                        Re?.response?.data?.detail ===
                        "Unauthenticated User, Please login first."
                    ) {
                        const refreshRe = await GenerateRefreshToken();

                        console.log(refreshRe, "REFRESH RE");

                        if (refreshRe?.data?.access_token) {
                            localStorage.setItem(
                                "refresh_token",
                                refreshRe?.data?.refresh_token
                            );
                            localStorage.setItem(
                                "token",
                                refreshRe?.data?.access_token
                            );
                            getActiveServices();
                        }else{
                            localStorage.clear()
                            dispatch({
                                type: "SET_USER_LOGGED_IN",
                                status: false,
                              });
                        }

                    } else {
                        toast.error("Something went wrong, Please try agian.");
                    }
                }
            }
        };
        getMeetingDetails();
        getActiveServices();
    }, [dispatch]);
    return (
        <>
            <ExportPreviewModal data={pdfData} setData={setPdfData} />
            <div className='heading'>
                <h1>
                    Welcome back,{" "}
                    {localStorage.getItem("name") ||
                        sessionStorage.getItem("name")}
                </h1>
                <p className='tag-line'>Track, manage your schedule calls.</p>
            </div>

            <div className='meeting-container'>
                <div className='heading'>
                    <h3>Upcomming Meeting</h3>
                    <select name='' id=''>
                        <option value="Today's">Today's</option>
                        <option value='Last Month'>Last Month</option>
                    </select>
                </div>
                <div className='meetings-list'>
                    {meetings?.length > 0 ? (
                        meetings.map((meeting) => (
                            <span
                                className='meeting-card'
                                key={"meeting" + meeting.id}>
                                <div className='product-details'>
                                    <p className='topic-name bold'>
                                        {meeting.category}
                                    </p>
                                    <ul>
                                        {meeting.products.map((prod) => (
                                            <li key={"prod" + prod.id}>
                                                {prod.title}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <span className='meeting-details'>
                                    <p>Meeting with {meeting.host_name}</p>
                                    <span className='grp'>
                                        <p className='bold'>When</p>
                                        <p className='time'>
                                            {`${
                                                DAYS[
                                                    new Date(
                                                        meeting.meet_time
                                                    ).getDay()
                                                ]
                                            }, ${new Date(meeting.meet_time)
                                                .toLocaleString()
                                                .slice(0, 2)} ${
                                                MONTHS[
                                                    new Date(
                                                        meeting.meet_time
                                                    ).getMonth()
                                                ]
                                            }, Time: ${new Date(
                                                meeting.meet_time
                                            )
                                                .toLocaleString()
                                                .slice(11, 17)}`}
                                        </p>
                                    </span>
                                    <span className='grp'>
                                        <p className='bold'>Where</p>
                                        <span className='gmeet-title'>
                                            <img src={Gmeet} alt='' />
                                            <p className='bold'>Google Meet</p>
                                        </span>
                                        <a
                                            href={meeting.meet_link}
                                            target='__black'>
                                            <span className='link'>
                                                <p>{meeting.meet_link}</p>
                                                <span className='arrow'>
                                                    <Arrow />
                                                </span>
                                            </span>
                                        </a>
                                    </span>
                                </span>
                            </span>
                        ))
                    ) : (
                        <p className='empty-list'>
                            You dont' have any meetings
                        </p>
                    )}
                </div>
            </div>
            {activeServices && (
                <div className='progress-container' ref={progressRef}>
                    <div className='heading'>
                        <h2>Progress</h2>
                        <div>
                            <span onClick={handleDownloadPdf}>
                                <Export />
                                <p>Export</p>
                            </span>
                            <select
                                name=''
                                id=''
                                value={selectedCategory}
                                onChange={(e) =>
                                    setSelectedCategory(e.target.value)
                                }>
                                <option value={""}>All</option>
                                {categories.map((cat) => (
                                    <option value={cat.id} key={"cat" + cat.id}>
                                        {cat.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {activeServices?.length > 0 ? (
                        activeServices
                            .filter(
                                (service) =>
                                    String(service.category_id) ===
                                        String(selectedCategory) ||
                                    selectedCategory === ""
                            )
                            .map((service) => (
                                <div
                                    className='progress-details'
                                    key={"active_service" + service.id}>
                                    <div className='service-details'>
                                        <p className='title'>{service.title}</p>
                                        <p className='description'>
                                            {service.description}
                                        </p>
                                    </div>

                                    <div className='time'>
                                        <span className='start'>
                                            Start Date :{" "}
                                            {`${
                                                DAYS[
                                                    new Date(
                                                        service.start_date
                                                    ).getDay()
                                                ]
                                            }, ${new Date(service.start_date)
                                                .toLocaleString()
                                                .slice(0, 2)} ${
                                                MONTHS[
                                                    new Date(
                                                        service.start_date
                                                    ).getMonth()
                                                ]
                                            } ${new Date(
                                                service.start_date
                                            ).getFullYear()}`}
                                        </span>
                                        {service?.end_date && <span className='end'>
                                            End Date :{" "}
                                            {`${
                                                DAYS[
                                                    new Date(
                                                        service.end_date
                                                    ).getDay()
                                                ]
                                            }, ${new Date(service.end_date)
                                                .toLocaleString()
                                                .slice(0, 2)} ${
                                                MONTHS[
                                                    new Date(
                                                        service.end_date
                                                    ).getMonth()
                                                ]
                                            } ${new Date(
                                                service.end_date
                                            ).getFullYear()}`}
                                        </span>}
                                    </div>

                                    <div className='progress-bar'>
                                        {/* set it's width from js */}
                                        <span
                                            className='progress'
                                            style={{
                                                width: service.progress + "%",
                                            }}></span>
                                    </div>

                                    <p className='last-update'>
                                        last update :{" "}
                                        {`${
                                            DAYS[
                                                new Date(
                                                    service.update_at
                                                ).getDay()
                                            ]
                                        }, ${new Date(service.update_at)
                                            .toLocaleString()
                                            .slice(0, 2)} ${
                                            MONTHS[
                                                new Date(
                                                    service.update_at
                                                ).getMonth()
                                            ]
                                        } ${new Date(
                                            service.update_at
                                        ).getFullYear()}`}
                                    </p>

                                    {service.updates.map((update, indx) => (
                                        <div
                                            className='work-details'
                                            key={"update" + indx}>
                                            <div className='custom-check-box'>
                                                <span className='checkmark'>
                                                    {update.isCompleted && (
                                                        <span className='checked'>
                                                            <Correct />
                                                        </span>
                                                    )}
                                                </span>
                                            </div>
                                            <div className='details'>
                                                <p className='topic'>
                                                    {update.title}
                                                </p>
                                                <p>{`${
                                                    DAYS[
                                                        new Date(
                                                            update.date
                                                        ).getDay()
                                                    ]
                                                }, ${new Date(update.date)
                                                    .toLocaleDateString()
                                                    .slice(0, 2)} ${
                                                    MONTHS[
                                                        new Date(
                                                            update.date
                                                        ).getMonth()
                                                    ]
                                                } ${new Date(
                                                    update.date
                                                ).getFullYear()}`}</p>
                                                {update.note && (
                                                    <p>
                                                        <span className='bold'>
                                                            Note:{" "}
                                                        </span>
                                                        {update.note}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))
                    ) : (
                        <p className='empty-list'>
                            You have not activated any service yet!!
                        </p>
                    )}
                </div>
            )}
        </>
    );
};

export default MyDashboard;
