import React, { useEffect, useState } from "react";
// import productImg from "../../Assets/images/product-img.png";

import "./cart.scss";
import ProductTable from "../ProductsAndService/Components/ProductTable";
import {
    GetCart,
    RemoveItemFromCart,
    CheckCouponCodeAvailable,
    GenerateRefreshToken,
} from "../../apiCall";
import { toast } from "react-toastify";
import { domainName } from "../../Constants";
import { displayRazorpay } from "../../utils/Razorpay";
import { RotatingLines } from "react-loader-spinner";
import { useStateValue } from "../../StateProvider";

// const CartItems = [
//     {
//         id: 1,
//         title: "India Company Registration",
//         quantity: 1,
//         price: 185323,
//         img: productImg,
//     },
//     {
//         id: 2,
//         title: "Franchise Model",
//         quantity: 1,
//         price: 185323,
//         img: productImg,
//     },
// ];
// const Products = [
//     {
//         id: 1,
//         title: "India Company Registration",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed seLorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed se",
//     },
//     {
//         id: 2,
//         title: "UAE Company Registration",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed seLorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed se",
//     },
//     {
//         id: 3,
//         title: "US Company Registration",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed seLorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed se",
//     },
//     {
//         id: 4,
//         title: "India Company Registration",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed seLorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed se",
//     },
//     {
//         id: 5,
//         title: "UAE Company Registration",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed seLorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed se",
//     },
//     {
//         id: 6,
//         title: "US Company Registration",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed seLorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ac ligula eget odio eleifend pretium sed se",
//     },
// ];
// const total = 370646;

const Cart = () => {
    const [cartItems, setCartItems] = useState();
    const [couponCode, setCouponCode] = useState();
    const [appliedCouponCodeDetails, setAppliedCouponCodeDetails] = useState();
    const [couponError, setCouponError] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [, dispatch] = useStateValue()

    const handleSubmit = () => {
        const payable_amount = appliedCouponCodeDetails
            ? appliedCouponCodeDetails.payable_amount
            : cartItems.total;
        displayRazorpay(
            payable_amount,
            false,
            appliedCouponCodeDetails?.id,
            cartItems?.total,
            appliedCouponCodeDetails?.applied_discount,
            setShowLoader
        );
    };
    const removeFromCart = async (id) => {
        const Re = await RemoveItemFromCart(id);
        // console.log(Re);

        if (Re?.data?.status[0].Error === "False") {
            setCartItems(Re.data.value);
        } else {
            if (Re?.data?.status) {
                toast.error(Re?.data?.status[0].ResponseMessage);
            } else {
                toast.error("Something went wrong, Please try agian.");
            }
        }
    };
    const submitCouponCode = async () => {
        const Re = await CheckCouponCodeAvailable(couponCode, cartItems.total);
        // console.log(Re);

        if (Re?.data?.status[0].Error === "False") {
            setAppliedCouponCodeDetails(Re.data.value);
            setCouponError();
        } else {
            setAppliedCouponCodeDetails();
            if (Re?.data?.status) {
                if (Re?.data?.status[0].Message === "form_error") {
                    setCouponError(Re?.data?.status[0].ResponseMessage);
                } else {
                    toast.error(Re?.data?.status[0].ResponseMessage);
                }
            } else {
                if (
                    Re?.response?.data?.detail ===
                    "Unauthenticated User, Please login first."
                ) {
                    const refreshRe = await GenerateRefreshToken();

                    console.log(refreshRe, "REFRESH RE");

                    if (refreshRe?.data?.access_token) {
                        localStorage.setItem(
                            "refresh_token",
                            refreshRe?.data?.refresh_token
                        );
                        localStorage.setItem(
                            "token",
                            refreshRe?.data?.access_token
                        );
                        submitCouponCode();
                    }else{
                        localStorage.clear()
                        dispatch({
                            type: "SET_USER_LOGGED_IN",
                            status: false,
                          });
                    }

                } else {
                    toast.error("Something went wrong, Please try agian.");
                }
            }
        }
    };
    useEffect(() => {
        const getCartDetails = async () => {
            const Re = await GetCart();
            // console.log(Re);
            if (Re?.data?.status[0].Error === "False") {
                setCartItems(Re.data.value);
            } else {
                if (Re?.data?.status) {
                    toast.error(Re?.data?.status[0].ResponseMessage);
                } else {
                    if (
                        Re?.response?.data?.detail ===
                        "Unauthenticated User, Please login first."
                    ) {
                        const refreshRe = await GenerateRefreshToken();

                        console.log(refreshRe, "REFRESH RE");

                        if (refreshRe?.data?.access_token) {
                            localStorage.setItem(
                                "refresh_token",
                                refreshRe?.data?.refresh_token
                            );
                            localStorage.setItem(
                                "token",
                                refreshRe?.data?.access_token
                            );
                            getCartDetails();
                        }else{
                            localStorage.clear()
                            dispatch({
                                type: "SET_USER_LOGGED_IN",
                                status: false,
                              });
                        }

                    } else {
                        toast.error("Something went wrong, Please try agian.");
                    }
                }
            }
        };
        getCartDetails();

        window.scrollTo(0, 0);
    }, [dispatch]);
    return cartItems?.items?.length > 0 ? (
        <div className='cart-page'>
            <div className='cart-main'>
                {
                    <div className='heading'>
                        <h2 className='bold'>Your Cart</h2>
                        <p className='bold'>{cartItems.items.length} Items</p>
                    </div>
                }
                <hr />
                <div className='cart-items'>
                    {cartItems.items.map((item) => (
                        <div className='product-info' key={"cart" + item.id}>
                            <div className='product-name'>
                                <img
                                    src={domainName + item.product_img}
                                    alt=''
                                />
                                <h3>{item.title}</h3>
                            </div>
                            <span className='price'>
                                <p className='amount'>
                                    ₹{item.price.toLocaleString()}
                                </p>
                                <p
                                    className='remove'
                                    onClick={() => removeFromCart(item.id)}>
                                    Remove
                                </p>
                            </span>
                        </div>
                    ))}
                </div>
                <hr />
                <div className='total'>
                    <p className='bold'>Total Payable</p>
                    <p className='bold price'>
                        {" "}
                        {appliedCouponCodeDetails && (
                            <span className='old-ammount'>
                                ₹{cartItems.total.toLocaleString()}
                            </span>
                        )}{" "}
                        ₹
                        {appliedCouponCodeDetails
                            ? appliedCouponCodeDetails.payable_amount.toLocaleString()
                            : cartItems.total.toLocaleString()}
                    </p>
                </div>
                <div className='coupon-code'>
                    <div className='input-grp'>
                        <input
                            type='text'
                            placeholder='Enter Coupon Code'
                            onChange={(e) => setCouponCode(e.target.value)}
                            value={couponCode}
                        />
                        <button
                            className='secondary'
                            onClick={submitCouponCode}>
                            Apply
                        </button>
                    </div>
                    <p className='form-error'>{couponError}</p>
                    {appliedCouponCodeDetails && (
                        <p className='form-success'>Coupon code applied.</p>
                    )}
                </div>
                <button className='primary' onClick={() => handleSubmit()}>
                    Pay Now
                </button>
            </div>
            {cartItems?.recommended_products?.length > 0 && (
                <div className='recommendation-services'>
                    <h3>Recommendation Services</h3>
                    {/* <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam luctus neque quis nisi pharetra, eu vestibulum felis
                    lacinia. Cras sollicitudin faucibus est nec porttitor.
                </p> */}
                    <ProductTable
                        products={cartItems.recommended_products}
                        type={"products"}
                    />
                </div>
            )}
            {showLoader && (
                <div className='loader'>
                    <RotatingLines
                        strokeColor='#E31E26'
                        strokeWidth='5'
                        animationDuration='0.75'
                        width='96'
                        visible={true}
                    />
                </div>
            )}
        </div>
    ) : (
        <h2 className='empty'>
            Your cart is empty, Please add some items to it.
        </h2>
    );
};

export default Cart;
