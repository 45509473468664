import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import { toast } from "react-toastify";

function ProtectedRoute({ children }) {
  const [{ isLoggedIn, loginModalShow }, dispatch] = useStateValue();
  let location = useLocation();
  if (!isLoggedIn && location.pathname === "/cart") {
    if (!loginModalShow) {
      dispatch({ type: "SET_LOGIN_MODAL_SHOW", status: true });
      sessionStorage.setItem("last_location", location.pathname);
    }
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  if (!isLoggedIn && location.pathname === "/usa-summit-2025") {
    if (!loginModalShow) {
      dispatch({ type: "SET_LOGIN_MODAL_SHOW", status: true });
      sessionStorage.setItem("last_location", location.pathname);
    }
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  if (!isLoggedIn && location.pathname === "/feedback") {
    if (!loginModalShow) {
      sessionStorage.setItem("last_location", location.pathname);
      dispatch({ type: "SET_LOGIN_MODAL_SHOW", status: true });
    }
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  if (!isLoggedIn && location.pathname === "/orders") {
    if (!loginModalShow) {
      dispatch({ type: "SET_LOGIN_MODAL_SHOW", status: true });
      sessionStorage.setItem("last_location", location.pathname);
    }

    return <Navigate to="/" state={{ from: location }} replace />;
  }
  if (!isLoggedIn && location.pathname === "/dashboard") {
    if (!loginModalShow) {
      dispatch({ type: "SET_LOGIN_MODAL_SHOW", status: true });
      sessionStorage.setItem("last_location", location.pathname);
    }

    return <Navigate to="/" state={{ from: location }} replace />;
  }
  if (!isLoggedIn && location.pathname === "/schedule-call") {
    if (!loginModalShow) {
      dispatch({ type: "SET_LOGIN_MODAL_SHOW", status: true });
      sessionStorage.setItem("last_location", location.pathname);
    }

    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
}

export default ProtectedRoute;
