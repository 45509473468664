import React from "react";
import "./exportPreview.scss";
import { useStateValue } from "../../../StateProvider";
import { jsPDF } from "jspdf";

const ExportPreview = ({ data, setData }) => {
    const [, dispatch] = useStateValue()
    const hideExportModal = () => {
        setData("")
        dispatch({
            type: "SET_EXPORT_PREVIEW_MODAL_SHOW",
            status: false,
        });
    }
    const generatePDF = () => {
        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
          (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        // console.log(pdf)
        pdf.save('progress.pdf');

        hideExportModal()
        setData("")
    }
    return (
        <div className='export-preview-main'>
            <p>Export</p>
            <div className='preview-window'>
                <span className='preview'>Preview</span>
                <img src={data} alt='' />
            </div>
            <div className="buttons">
                <button className="primary" onClick={generatePDF}>Export</button>
                <button className="secondary" onClick={hideExportModal}>Cancel</button>
            </div>
        </div>
    );
};

export default ExportPreview;
