import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useStateValue } from "../../StateProvider";

import Logo from "../../Assets/icons/logo.svg";
import Home from "../../Assets/icons/home.svg";
import About from "../../Assets/icons/attention-circle.svg";
import Products from "../../Assets/icons/product.svg";
import Contact from "../../Assets/icons/contact.svg";
import Order from "../../Assets/icons/orders.svg";
import Dashboard from "../../Assets/icons/dashboard.svg";
import { ReactComponent as Message } from "../../Assets/icons/message.svg";
import Edit from "../../Assets/icons/edit.svg";
import Report from "../../Assets/icons/flag.svg";
import LogoutIcon from "../../Assets/icons/log-out.svg";

import "bootstrap/dist/css/bootstrap.min.css";
import "./menuOffCanvas.scss";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineFeedback } from "react-icons/md";
function MenuOffCanvas() {
    const [{ showNavCanvas, isLoggedIn }, dispatch] = useStateValue();

    const handleOffCanvas = (status) => {
        dispatch({
            type: "SET_NAV_CANVAS_SHOW",
            status: status,
        });
    };

    const openLoginForm = (status) => {
        handleOffCanvas(false);
        dispatch({
            type: "SET_LOGIN_MODAL_SHOW",
            status: status,
        });
    };
    const handleLogOut = () => {
        handleOffCanvas(false);
        dispatch({
            type: "SET_USER_LOGGED_IN",
            status: false,
        });
        localStorage.clear();
        sessionStorage.clear();
    };
    const navigateTo = useNavigate();

    return (
        <Offcanvas
            show={showNavCanvas}
            placement='end'
            onHide={() => handleOffCanvas(false)}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    <Link to='/'>
                        <img src={Logo} alt='' />
                    </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <span
                    className='item'
                    onClick={() => {
                        navigateTo("/");
                        handleOffCanvas(false);
                    }}>
                    <img src={Home} alt='' srcset='' />
                    <p>Home</p>
                </span>
                <span
                    className='item'
                    onClick={() => {
                        navigateTo("/about-us");
                        sessionStorage.setItem('scroll_about', 0)
                        handleOffCanvas(false);
                    }}>
                    <img src={About} alt='' srcset='' />
                    <p>About Us</p>
                </span>
                <span
                    className='item'
                    onClick={() => {
                        navigateTo("/products-and-service");
                        handleOffCanvas(false);
                    }}>
                    <img src={Products} alt='' srcset='' />
                    <p>Products</p>
                </span>
                <span
                    className='item'
                    onClick={() => {
                        navigateTo("/contact-us");
                        handleOffCanvas(false);
                    }}>
                    <img src={Contact} alt='' srcset='' />
                    <p>Contact Us</p>
                </span>
                <span
                    className='item'
                    onClick={() => {
                        navigateTo("/feedback");
                        handleOffCanvas(false);
                    }}>
                    <MdOutlineFeedback size={24} />
                    <p>Feedback</p>
                </span>
                {isLoggedIn === false ? (
                    <button
                        className='primary-btn'
                        onClick={() => openLoginForm(true)}>
                        Login
                    </button>
                ) : (
                    <>
                        <span
                            className='item'
                            onClick={() => {
                                navigateTo("/orders");
                                handleOffCanvas(false);
                            }}>
                            <img src={Order} alt='' srcset='' />
                            <p>My Orders</p>
                        </span>
                        <hr />
                        <span
                            className='item'
                            onClick={() => {
                                navigateTo("/dashboard");
                                handleOffCanvas(false);
                            }}>
                            <img src={Dashboard} alt='' srcset='' />
                            <p>Dashboard</p>
                        </span>
                        {/* <span
                            className='item'
                            onClick={() => {
                                navigateTo("/dashboard?message=true");
                                handleOffCanvas(false);
                            }}>
                            <Message />
                            <p>Message</p>
                        </span> */}
                        <span
                            className='item'
                            onClick={() => {
                                navigateTo("/dashboard?editprofile=true");
                                handleOffCanvas(false);
                            }}>
                            <img src={Edit} alt='' srcset='' />
                            <p>Edit Profile</p>
                        </span>
                        <span
                            className='item'
                            onClick={() => {
                                navigateTo("/dashboard?support=true");
                                handleOffCanvas(false);
                            }}>
                            <img src={Report} alt='' srcset='' />
                            <p>Reporting</p>
                        </span>
                        <span className='item' onClick={handleLogOut}>
                            <img src={LogoutIcon} alt='' srcset='' />
                            <p>Log Out</p>
                        </span>
                    </>
                )}
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default MenuOffCanvas;
