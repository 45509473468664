import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./cartQuestions.scss";
import { GetCartQuestions } from "../../../apiCall";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import { CACHE_TIME } from "../../../Constants";
import { useStateValue } from "../../../StateProvider";

const CartQuestions = () => {
    const { id } = useParams();
    const [{ isLoggedIn, cartQuestionsAnswers }, dispatch] = useStateValue();
    const [cartQuestions, setCartQuestions] = useState(undefined);
    const session_cart_que_obj = sessionStorage.getItem("cart_questions");
    const [cartAnswers, setCartAnswers] = useState(
        session_cart_que_obj
            ? JSON.parse(session_cart_que_obj)[id]
                ? JSON.parse(session_cart_que_obj)[id]
                : {}
            : {}
    );
    // const [cartAnswersErrors, setCartAnswersErrors] = useState({});

    // const validateSessionAnswers = () => {
    //     let hasErrors = false;
    //     for (let j = 0; j < cartQuestions?.length; j++) {
    //         const question = cartQuestions[j];
    //         const key = question.id;
    //         const answer = cartAnswers[key];
    //         if (
    //             !question.dependent_question ||
    //             cartAnswers[question.dependent_question] ===
    //                 question.dependent_answer
    //         ) {
    //             if (typeof answer === "object") {
    //                 if (answer.length) {
    //                     setCartAnswersErrors((pre) => {
    //                         const new_errors = { ...pre };
    //                         new_errors[key] = false;
    //                         return new_errors;
    //                     });
    //                 } else {
    //                     // console.log(key);
    //                     setCartAnswersErrors((pre) => {
    //                         const new_errors = { ...pre };
    //                         new_errors[key] = true;
    //                         return new_errors;
    //                     });
    //                     hasErrors = true;
    //                 }
    //             } else {
    //                 if (answer) {
    //                     setCartAnswersErrors((pre) => {
    //                         const new_errors = { ...pre };
    //                         new_errors[key] = false;
    //                         return new_errors;
    //                     });
    //                 } else {
    //                     // console.log(key);
    //                     setCartAnswersErrors((pre) => {
    //                         const new_errors = { ...pre };
    //                         new_errors[key] = true;
    //                         return new_errors;
    //                     });
    //                     hasErrors = true;
    //                 }
    //             }
    //         } else {
    //             setCartAnswersErrors((pre) => {
    //                 const new_errors = { ...pre };
    //                 new_errors[key] = true;
    //                 return new_errors;
    //             });
    //         }
    //     }
    //     return hasErrors;
    // };

    const handleNext = async () => {
        // const hasErrors = validateSessionAnswers();
        // if (hasErrors) {
        //     return;
        // }
        
        if (!isLoggedIn) {
            dispatch({
                type: "SET_LOGIN_MODAL_SHOW",
                status: true,
            });
            dispatch({
                type: "SET_CART_QUESTIONS_MODAL_SHOW",
                status: false,
            });
            return;
        }
        
        const cart_answers = cartQuestions?.map((question) => {
            if (Array.isArray(cartAnswers[question.id])){
                const q_a_obj = {
                    question: question.question,
                    answer: cartAnswers[question.id]?.includes('Other') ? [...cartAnswers[question.id], cartAnswers[question.id+'other']] : cartAnswers[question.id],
                };
                return q_a_obj;
            }else{
                const q_a_obj = {
                    question: question.question,
                    answer: cartAnswers[question.id] === 'Other' ? cartAnswers[question.id+'other'] : cartAnswers[question.id],
                };
                return q_a_obj;
            }
        });
        
        const new_cart_answers = {...cartQuestionsAnswers}
        new_cart_answers[id] = cart_answers;
        dispatch({
            type: "SET_CART_QUESTIONS_ANSWERS",
            status: new_cart_answers,
        });
        dispatch({
            type: "SET_CART_QUESTIONS_MODAL_SHOW",
            status: false,
        });
        dispatch({
            type: "SET_DOCUMENTS_MODAL_SHOW",
            status: true,
        });
        // const Re = await AddItemToCart({
        //     id,
        //     question_answers: cart_answers ? cart_answers : [],
        // });
        // // console.log(Re);

        // if (Re?.data?.status[0].Error === "False") {
        //     dispatch({
        //         type: "SET_CART_QUESTIONS_MODAL_SHOW",
        //         status: false,
        //     });
        //     dispatch({
        //         type: "SET_ADD_TO_CART_MODAL_SHOW",
        //         status: true,
        //     });
        // } else {
        //     if (Re?.data?.status) {
        //         // console.log(Re.data.status[0].Message);
        //         if (Re.data.status[0].Message === "present") {
        //             toast.success(Re?.data?.status[0].ResponseMessage);
        //             dispatch({
        //                 type: "SET_CART_QUESTIONS_MODAL_SHOW",
        //                 status: false,
        //             });
        //         } else {
        //             toast.error(Re?.data?.status[0].ResponseMessage);
        //         }
        //     } else {
        //         toast.error("Something went wrong, Please try agian.");
        //     }
        // }
    };
    useQuery(["cart-questions"], () => GetCartQuestions(id), {
        // staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onSuccess: (data) => {
            // console.log(data);
            if (data.data?.status[0].Error === "False") {
                setCartQuestions(data.data.value?.cart_questions);
            } else {
                if (data?.data?.status) {
                    toast.error(data?.data?.status[0].ResponseMessage);
                } else {
                    toast.error("Something went wrong, Please try agian.");
                }
            }
        },
        onError: (err) => {
            // console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });

    useEffect(() => {
        const session_obj = sessionStorage.getItem("cart_questions")
            ? JSON.parse(sessionStorage.getItem("cart_questions"))
            : {};
        const new_obj = { ...session_obj };

        if (typeof cartAnswers === "object") {
            new_obj[id] = cartAnswers;
        }
        // console.log(new_obj);
        sessionStorage.setItem("cart_questions", JSON.stringify(new_obj));
    }, [cartAnswers, id]);

    useEffect(() => {
        if (cartQuestions === null || cartQuestions?.length === 0) {
            dispatch({
                type: "SET_CART_QUESTIONS_MODAL_SHOW",
                status: false,
            });
            dispatch({
                type: "SET_DOCUMENTS_MODAL_SHOW",
                status: true,
            });
        }
    }, [cartQuestions, dispatch]);

    return (
        cartQuestions?.length > 0 && (
            <div className='cart-questions-main'>
                <h2>
                    Before buying this service, would you please answer a few
                    questions?
                </h2>
                {cartQuestions?.map(
                    (question) =>
                        (!question.dependent_question ||
                            cartAnswers[question.dependent_question] ===
                                question.dependent_answer) && (
                            <div
                                className='input-grp cart-questions'
                                key={"question" + question.id}>
                                <label htmlFor='notes'>
                                    {question.question}
                                </label>
                                {question.input_type === "MultiSelect" && (
                                    <p className='information'>
                                        Hold down the Ctrl (windows) or Command
                                        (Mac) button to select multiple options.
                                    </p>
                                )}
                                {/* {// console.log(cartAnswers)}
                                {// console.log(cartAnswers[question.id])} */}
                                {question.input_type === "Text" && (
                                    <input
                                        type='text'
                                        name='note'
                                        placeholder='Type here...'
                                        value={
                                            cartAnswers[question.id]
                                                ? cartAnswers[question.id]
                                                : ""
                                        }
                                        onChange={(e) =>
                                            setCartAnswers((pre) => {
                                                const new_answers = { ...pre };
                                                new_answers[question.id] =
                                                    e.target.value;
                                                return new_answers;
                                            })
                                        }
                                    />
                                )}
                                {question.input_type === "Dropdown" && (
                                    <>
                                        <select
                                            value={
                                                cartAnswers[question.id]
                                                ? cartAnswers[question.id]
                                                : ""
                                            }
                                            onChange={(e) =>
                                                setCartAnswers((pre) => {
                                                    const new_answers = { ...pre };
                                                    new_answers[question.id] =
                                                    e.target.value;
                                                    return new_answers;
                                                })
                                            }>
                                            <option value=''>Select Answer</option>
                                            {question.options.map(
                                                (option) => (
                                                    <option
                                                        key={`option-${question.id}-${option.id}`}
                                                        value={option.value}>
                                                        {option.value}
                                                    </option>
                                                )
                                                )}
                                        </select>
                                        {cartAnswers[question.id] === 'Other' && <input
                                            type='text'
                                            name='other'
                                            placeholder='Type here for above question...'
                                            value={
                                                cartAnswers[question.id + 'other']
                                                ? cartAnswers[
                                                    question.id + 'other'
                                                ]
                                                : ""
                                            }
                                            onChange={(e) =>
                                                setCartAnswers((pre) => {
                                                    const new_answers = {
                                                        ...pre,
                                                    };
                                                    new_answers[
                                                        question.id + 'other'
                                                    ] = e.target.value;
                                                    return new_answers;
                                                })
                                            }
                                        />}
                                    </>
                                )}
                                {question.input_type === "MultiSelect" && (
                                    <>
                                        <select
                                            value={
                                                cartAnswers[question.id]
                                                ? cartAnswers[question.id]
                                                : []
                                            }
                                            onChange={(e) =>
                                                setCartAnswers((pre) => {
                                                    const new_answers = { ...pre };
                                                    new_answers[question.id] =
                                                    Array.from(
                                                        e.target
                                                        .selectedOptions,
                                                        (option) => option.value
                                                        );
                                                        return new_answers;
                                                    })
                                                }
                                                multiple>
                                            {question.options.map(
                                                (option) => (
                                                    <option
                                                    key={`option-${question.id}-${option.id}`}
                                                    value={option.value}>
                                                        {option.value}
                                                    </option>
                                                )
                                                )}
                                        </select>
                                        {cartAnswers[question.id]?.includes('Other') && <input
                                            type='text'
                                            name='other'
                                            placeholder='Type here for above question...'
                                            value={
                                                cartAnswers[question.id + 'other']
                                                ? cartAnswers[
                                                    question.id + 'other'
                                                ]
                                                : ""
                                            }
                                            onChange={(e) =>
                                                setCartAnswers((pre) => {
                                                    const new_answers = {
                                                        ...pre,
                                                    };
                                                    new_answers[
                                                        question.id + 'other'
                                                    ] = e.target.value;
                                                    return new_answers;
                                                })
                                            }
                                        />}
                                </>
                                )}
                                {/* {cartAnswersErrors[question.id] && (
                                    <p className='form-error'>
                                    This field is required.
                                    </p>
                                )} */}
                            </div>
                        )
                )}
                <button className='primary' onClick={() => handleNext()}>
                    Next
                </button>
            </div>
        )
    );
};

export default CartQuestions;
