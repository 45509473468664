import React from "react";
import {ReactComponent as ArrowBlack} from "../../../Assets/icons/black-arrow.svg"
// import {ReactComponent as RedArrow} from "../../../Assets/icons/red-arrow.svg"

import "./categoriesSelect.scss";
import { toast } from "react-toastify";

const CategoriesSelect = ({selectedCategories, setSelectedCategories, categories, setStep}) => {
    const handleCategoriesSelect = (e, id) => {
        e.preventDefault();
        if (selectedCategories.includes(id)) {
            setSelectedCategories((pre) =>
                pre.filter((categoryId) => categoryId !== id)
            );
        } else {
            setSelectedCategories((pre) => [...pre, id]);
        }
    };

    const handleNext = () => {
        if(selectedCategories.length > 0){
            setStep(pre => pre+1)
        }else{
            toast.warn("Please select any category first.")
        }
    }
    return (
        <div className='category-select'>
            <span
                className='arrow primary-btn'
                onClick={() => setStep(pre => pre - 1)}
            >
                <ArrowBlack />
            </span>
            <h3>
                Great to connect with you!<br></br> How can we assist you with
                our services?
            </h3>
            {categories?.length > 0 && (
                <div className='choices'>
                    {categories.map((category) => (
                        <button
                            key={"category" + category.id}
                            className={`primary-btn ${
                                selectedCategories.includes(category.id)
                                    ? "selected"
                                    : ""
                            }`}
                            onClick={(e) =>
                                handleCategoriesSelect(e, category.id)
                            }>
                            {category.title}
                        </button>
                    ))}
                </div>
            )}
            {/* <span className="continue-button" onClick={handleNext}>Continue <RedArrow/></span> */}
            <button
                className='primary-btn'
                onClick={handleNext}>
                Continue
            </button>
        </div>
    );
};

export default CategoriesSelect;
