import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { useStateValue } from "../../StateProvider";
import { ReactComponent as Eye } from "../../Assets/icons/eye.svg";
import { ReactComponent as ClosedEye } from "../../Assets/icons/eye-off.svg";
import { ReactComponent as Checked } from "../../Assets/icons/correct.svg";
import { ReactComponent as Google } from "../../Assets/icons/google.svg";
// import { ReactComponent as Facebook } from "../../Assets/icons/facebook-color.svg";
// import { ReactComponent as Apple } from "../../Assets/icons/apple.svg";
import Cross from "../../Assets/icons/close.svg";
import "./login.scss";
import { SignIn, SignUp, googleLogin } from "../../apiCall";
import { toast } from "react-toastify";
import { domainName } from "../../Constants";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";

const Login = ({ formType, setFormType }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const navigateTo = useNavigate();

  const [, dispatch] = useStateValue();

  const openLoginForm = (status) => {
    dispatch({
      type: "SET_LOGIN_MODAL_SHOW",
      status: status,
    });
  };
  const handleForgotPasswordModal = (status) => {
    openLoginForm(false);
    dispatch({
      type: "SET_FORGOT_PASSWORD_MODAL_SHOW",
      status: status,
    });
  };

  const verifyGoogleUser = async (data) => {
    const re = await googleLogin(data.access_token);
    if (re.status !== 200) {
      toast.error("Something went wrong, Please reload the page.");
    } else {
      if (re.data.status[0].Error === "True") {
        // setError("general", {type: "general", message: re.data.status[0].ResponseMessage})
        toast.error(re.data.status[0].ResponseMessage);
        return;
      } else {
        localStorage.setItem("token", re.data.access_token);
        localStorage.setItem("refresh_token", re.data.refresh_token);
        localStorage.setItem("name", re.data.value?.name);
        localStorage.setItem("email", re.data.value?.email);
        localStorage.setItem("country_code", re.data.value?.country_code);
        localStorage.setItem("phone_number", re.data.value?.phone_number);
        localStorage.setItem(
          "profile_img",
          domainName + re.data.value?.profile_img
        );

        if (!re.data.value?.has_basic_details) {
          navigateTo("/welcome");
        }
        dispatch({
          type: "SET_USER_LOGGED_IN",
          status: true,
        });
        dispatch({
          type: "SET_PROFILE_IMAGE",
          status: domainName + re.data.value?.profile_img,
        });
        openLoginForm(false);
        navigateTo(sessionStorage.getItem("last_location"));
        sessionStorage.setItem("last_location", "");
      }
    }
  };

  // google login
  const doGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse, "token response");
      verifyGoogleUser(tokenResponse);
    },
    onError: (error) => console.log("error", error),
  });

  const handleFormSubmit = async (data) => {
    if (formType === "signup") {
      const re = await SignUp(data);
      console.log(re);

      if (re.status !== 200) {
        toast.error("Something went wrong, Please reload the page.");
      } else {
        if (re.data.status[0].Error === "True") {
          // setError("general", {type: "general", message: re.data.status[0].ResponseMessage})
          toast.error(re.data.status[0].ResponseMessage);
          return;
        } else {
          // if (rememberMe) {
          localStorage.setItem("token", re.data.access_token);
          localStorage.setItem("refresh_token", re.data.refresh_token);
          localStorage.setItem("name", re.data.value?.name);
          localStorage.setItem("email", re.data.value?.email);
          localStorage.setItem("country_code", re.data.value?.country_code);
          localStorage.setItem("phone_number", re.data.value?.phone_number);
          localStorage.setItem(
            "profile_img",
            domainName + re.data.value?.profile_img
          );
          // }
          // else {
          //     sessionStorage.setItem("token", re.data.access_token);
          //     sessionStorage.setItem("name", re.data.value?.name);
          //     sessionStorage.setItem("email", re.data.value?.email);
          //     sessionStorage.setItem(
          //         "country_code",
          //         re.data.value?.country_code
          //     );
          //     sessionStorage.setItem(
          //         "phone_number",
          //         re.data.value?.phone_number
          //     );
          //     sessionStorage.setItem(
          //         "profile_img",
          //         domainName + re.data.value?.profile_img
          //     );
          // }
          navigateTo("/welcome");
          dispatch({
            type: "SET_USER_LOGGED_IN",
            status: true,
          });
          dispatch({
            type: "SET_PROFILE_IMAGE",
            status: domainName + re.data.value?.profile_img,
          });
          openLoginForm(false);
        }
      }
    } else {
      console.log(data);
      const re = await SignIn(data);
      console.log(re);

      if (re.status !== 200) {
        toast.error("Something went wrong, Please reload the page.");
      } else {
        if (re.data.status[0].Error === "True") {
          // setError("general", {type: "general", message: re.data.status[0].ResponseMessage})
          toast.error(re.data.status[0].ResponseMessage);
          return;
        } else {
          // if (rememberMe) {
          localStorage.setItem("token", re.data.access_token);
          localStorage.setItem("refresh_token", re.data.refresh_token);
          localStorage.setItem("name", re.data.value?.name);
          localStorage.setItem("email", re.data.value?.email);
          localStorage.setItem("country_code", re.data.value?.country_code);
          localStorage.setItem("phone_number", re.data.value?.phone_number);
          localStorage.setItem(
            "profile_img",
            domainName + re.data.value?.profile_img
          );
          // }
          // else {
          //     sessionStorage.setItem("token", re.data.access_token);
          //     sessionStorage.setItem("name", re.data.value?.name);
          //     sessionStorage.setItem("email", re.data.value?.email);
          //     sessionStorage.setItem(
          //         "country_code",
          //         re.data.value?.country_code
          //     );
          //     sessionStorage.setItem(
          //         "phone_number",
          //         re.data.value?.phone_number
          //     );
          //     sessionStorage.setItem(
          //         "profile_img",
          //         domainName + re.data.value?.profile_img
          //     );
          // }

          if (!re.data.value?.has_basic_details) {
            navigateTo("/welcome");
          }
          dispatch({
            type: "SET_USER_LOGGED_IN",
            status: true,
          });
          dispatch({
            type: "SET_PROFILE_IMAGE",
            status: domainName + re.data.value?.profile_img,
          });
          openLoginForm(false);
          navigateTo(sessionStorage.getItem("last_location"));
          sessionStorage.setItem("last_location", "");
        }
      }
    }
  };
  return (
    <div className="login-main">
      <img
        src={Cross}
        className="cross"
        alt=""
        onClick={() => openLoginForm(false)}
      />
      <div className="heading">
        <h2>
          {formType === "signup"
            ? "Create an account"
            : "Login in to your account"}
        </h2>
        <p>
          {formType === "signup" ? "Welcome back! " : ""}Please enter your
          details.
        </p>
      </div>
      <div className="body">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          {formType === "signup" && (
            <div className="input-grp">
              <label htmlFor="name">Name</label>
              <input
                type="name"
                placeholder="Enter your name"
                {...register("name", {
                  require: true,
                })}
              />
              {errors.name?.type === "required" && (
                <p className="form-error">Name is required.</p>
              )}
            </div>
          )}
          <div className="input-grp">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              placeholder="Enter your email"
              {...register("email", {
                required: true,
              })}
            />
            {errors.email?.type === "required" && (
              <p className="form-error">Email is required.</p>
            )}
          </div>
          <div className="input-grp">
            <label htmlFor="password">Password</label>
            <span className="password-input">
              <input
                type={isPasswordVisible ? "text" : "password"}
                placeholder="Enter your password"
                {...register("password", {
                  required: true,
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}$/i,
                })}
              />
              {isPasswordVisible ? (
                <ClosedEye onClick={() => setIsPasswordVisible(false)} />
              ) : (
                <Eye onClick={() => setIsPasswordVisible(true)} />
              )}
            </span>
            {errors.password?.type === "required" && (
              <p className="form-error">Password is required.</p>
            )}
            {errors.password?.type === "pattern" && (
              <p className="form-error">
                Must Be Contains Uppercase, Lowercase Letters, Numbers, Special
                Characters And Length Is Greater Than 8 Character And Less Then
                16 Character.
              </p>
            )}
          </div>
          <div className="remember-forgot">
            {/* <div
                            className='remember'
                            onClick={() => setRememberMe((pre) => !pre)}>
                            <span
                                className={`check-box ${
                                    rememberMe ? "checked" : ""
                                }`}>
                                {rememberMe && <Checked />}
                            </span>
                            <p>Remember me</p>
                        </div> */}
            <p onClick={() => handleForgotPasswordModal(true)}>
              forgot password?
            </p>
          </div>
          <button className="primary" type="submit">
            {formType === "signup" ? "Get Started" : "Log In"}
          </button>
        </form>
        <div className="or">
          <hr />
          <span>OR</span>
          <hr />
        </div>
        <div className="social-login">
          <span onClick={doGoogleLogin}>
            <Google />
          </span>
        </div>
      </div>
      <div className="footer">
        <p>
          {formType === "signup" ? (
            <p>
              Already have an account?{" "}
              <span
                className="bold"
                onClick={() => {
                  setFormType("signin");
                  reset();
                }}
              >
                Log In
              </span>
            </p>
          ) : (
            <p>
              Don't have an account?{" "}
              <span
                className="bold"
                onClick={() => {
                  setFormType("signup");
                  reset();
                }}
              >
                Create an account
              </span>
            </p>
          )}
        </p>
      </div>
    </div>
  );
};

export default Login;
