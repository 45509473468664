import React from "react";
import Modal from "react-bootstrap/Modal";

import { useStateValue } from "./StateProvider";
import AddToCart from "./views/ProductDetails/components/AddToCart";
import "./modal.scss";
import Login from "./Components/Login/Login";

// import SidePanelPhone from "./views/Dashboard/components/SidePanelPhone";
// import MeetingDetails from './views/Dashboard/components/MeetingDetails';
import ChangePasswordPopUp from "./views/Dashboard/components/ChangePasswordPopUp";
import CreateSupportTicket from "./views/Dashboard/components/CreateSupportTicket";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword";
import ExportPreview from "./views/Dashboard/components/ExportPreview";
import CartQuestions from "./views/ProductDetails/components/CartQuestions";
import Documents from "./views/ProductDetails/components/Documents";

export function LoginModal(props) {
    const [{ loginModalShow }, dispatch] = useStateValue();
    const setLoginModalShow = () => {
        dispatch({
            type: "SET_LOGIN_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={loginModalShow}
            onHide={() => setLoginModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <Login
                    formType={props.loginFormType}
                    setFormType={props.setFormType}
                />
            </Modal.Body>
        </Modal>
    );
}

export function ForgotPasswordModal(props) {
    const [{ forgotPasswordModalShow }, dispatch] = useStateValue();
    const setForgotPasswordModalShow = () => {
        dispatch({
            type: "SET_FORGOT_PASSWORD_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={forgotPasswordModalShow}
            onHide={() => setForgotPasswordModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <ForgotPassword />
            </Modal.Body>
        </Modal>
    );
}

export function AddToCartModal(props) {
    const [{ addToCartModalShow }, dispatch] = useStateValue();
    const setAddToCartModalShow = () => {
        dispatch({
            type: "SET_ADD_TO_CART_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={addToCartModalShow}
            onHide={() => setAddToCartModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <AddToCart
                    id={props.id}
                    description={props.description}
                    product_img={props.product_img}
                    title={props.title}
                    price={props.price}
                />
            </Modal.Body>
        </Modal>
    );
}

export function DocumentsModal(props) {
    const [{ documentsModalShow }, dispatch] = useStateValue();
    const setDocumentsModalShow = () => {
        dispatch({
            type: "SET_DOCUMENTS_MODAL_SHOW",
            status: false,
        });
    };

    return (
        <Modal
            {...props}
            show={documentsModalShow}
            onHide={() => setDocumentsModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <Documents required_documents={props.documents} id={props.id} />
            </Modal.Body>
        </Modal>
    );
}

export function CartQuestionsModal(props) {
    const [{ cartQuestionsModalShow }, dispatch] = useStateValue();
    const setCartQuestionsModalShow = () => {
        dispatch({
            type: "SET_CART_QUESTIONS_MODAL_SHOW",
            status: false,
        });
    };

    return (
        <Modal
            {...props}
            show={cartQuestionsModalShow}
            onHide={() => setCartQuestionsModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <CartQuestions />
            </Modal.Body>
        </Modal>
    );
}

export function ChangePasswordModal(props) {
    const [{ changePasswordModalShow }, dispatch] = useStateValue();
    const setChangePasswordModalShow = () => {
        dispatch({
            type: "SET_CHANGE_PASSWORD_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={changePasswordModalShow}
            onHide={() => setChangePasswordModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <ChangePasswordPopUp />
            </Modal.Body>
        </Modal>
    );
}

export function CreateSupportTicketModal(props) {
    const [{ createSupportTicketModalShow }, dispatch] = useStateValue();
    const setCreateSupportTicketModalShow = () => {
        dispatch({
            type: "SET_CREATE_SUPPORT_TICKET_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={createSupportTicketModalShow}
            onHide={() => setCreateSupportTicketModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <CreateSupportTicket setTickets={props.setTickets} />
            </Modal.Body>
        </Modal>
    );
}

export function ExportPreviewModal(props) {
    const [{ exportPreviewModalShow }, dispatch] = useStateValue();
    const setExportPreviewModalShow = () => {
        dispatch({
            type: "SET_EXPORT_PREVIEW_MODAL_SHOW",
            status: false,
        });
    };
    return (
        <Modal
            {...props}
            show={exportPreviewModalShow}
            onHide={() => setExportPreviewModalShow(false)}
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered>
            <Modal.Body>
                <ExportPreview data={props.data} setData={props.setData} />
            </Modal.Body>
        </Modal>
    );
}
