import React, { useState } from "react";
import { ReactComponent as Cross } from "../../../Assets/icons/close.svg";
import { ReactComponent as Eye } from "../../../Assets/icons/eye.svg";
import { ReactComponent as ClosedEye } from "../../../Assets/icons/eye-off.svg";

import "./changePasswordPopUp.scss";
import { useStateValue } from "../../../StateProvider";
import { useForm } from "react-hook-form";
import { ChangePassword, GenerateRefreshToken } from "../../../apiCall";
import { toast } from "react-toastify";

const ChangePasswordPopUp = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
        useState(false);
    const [isPasswordMismatch, setIsPasswordMismatch] = useState("")

    const [, dispatch] = useStateValue();
    const hideChangePasswordModal = () => {
        dispatch({
            type: "SET_CHANGE_PASSWORD_MODAL_SHOW",
            status: false,
        });
    };
    const handleFormSubmit = async (data) => {
        if(data.newPassword !== data.confirmPassword){
            setIsPasswordMismatch("New password and confirm password must be same.")
        }else{
            setIsPasswordMismatch("")
        }

        const Re = await ChangePassword(data)
        // console.log(Re)

        if(Re.data?.status[0].Error === 'False'){
            toast.success("Password changed successfully")
            reset()
            hideChangePasswordModal()
        }else{
            if(Re.data?.status){
                toast.error(Re.data.status[0].ResponseMessage)
            }if (
                Re?.response?.data?.detail ===
                "Unauthenticated User, Please login first."
            ) {
                const refreshRe = await GenerateRefreshToken();

                console.log(refreshRe, "REFRESH RE");

                if (refreshRe?.data?.access_token) {
                    localStorage.setItem(
                        "refresh_token",
                        refreshRe?.data?.refresh_token
                    );
                    localStorage.setItem(
                        "token",
                        refreshRe?.data?.access_token
                    );
                    handleFormSubmit(data);
                }else{
                    localStorage.clear()
                    dispatch({
                        type: "SET_USER_LOGGED_IN",
                        status: false,
                      });
                }

            } else {
                toast.error("Something went wrong, Please try agian.");
            }
        }
    }
    return (
        <div className='change-password-pop-up-main'>
            <Cross onClick={hideChangePasswordModal} className='cross' />
            <div className='heading'>
                <h3>Change Password?</h3>
                <p>To change your password, you need to create a password.</p>
            </div>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className='input-grp'>
                    <label htmlFor='password'>Old Password</label>
                    <span className='password-input'>
                        <input
                            type={isPasswordVisible ? "text" : "password"}
                            placeholder='Enter your password'
                            {...register("oldPassword", {
                                required: true,
                                pattern:
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}$/i,
                            })}
                        />
                        {isPasswordVisible ? (
                            <ClosedEye
                                onClick={() => setIsPasswordVisible(false)}
                            />
                        ) : (
                            <Eye onClick={() => setIsPasswordVisible(true)} />
                        )}
                    </span>
                    {errors.oldPassword?.type === "required" && (
                        <p className='form-error'>Old Password is required.</p>
                    )}
                    {errors.oldPassword?.type === "pattern" && (
                        <p className='form-error'>
                            Must Be Contains Uppercase, Lowercase Letters,
                            Numbers, Special Characters And Length Is Greater
                            Than 8 Character And Less Then 16 Character.
                        </p>
                    )}
                </div>
                <div className='input-grp'>
                    <label htmlFor='password'>New Password</label>
                    <span className='password-input'>
                        <input
                            type={isPasswordVisible ? "text" : "password"}
                            placeholder='Enter your password'
                            {...register("newPassword", {
                                required: true,
                                pattern:
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}$/i,
                            })}
                        />
                        {isPasswordVisible ? (
                            <ClosedEye
                                onClick={() => setIsPasswordVisible(false)}
                            />
                        ) : (
                            <Eye onClick={() => setIsPasswordVisible(true)} />
                        )}
                    </span>
                    {errors.newPassword?.type === "required" && (
                        <p className='form-error'>Old Password is required.</p>
                    )}
                    {errors.newPassword?.type === "pattern" && (
                        <p className='form-error'>
                            Must Be Contains Uppercase, Lowercase Letters,
                            Numbers, Special Characters And Length Is Greater
                            Than 8 Character And Less Then 16 Character.
                        </p>
                    )}
                </div>
                <div className='input-grp'>
                    <label htmlFor='confirm-password'>Confirm Password</label>
                    <span className='password-input'>
                        <input
                            type={
                                isConfirmPasswordVisible ? "text" : "password"
                            }
                            placeholder='Enter your password'
                            {...register("confirmPassword", {
                                required: true,
                                pattern:
                                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{6,20}$/i,
                            })}
                        />
                        {isConfirmPasswordVisible ? (
                            <ClosedEye
                                onClick={() =>
                                    setIsConfirmPasswordVisible(false)
                                }
                            />
                        ) : (
                            <Eye
                                onClick={() =>
                                    setIsConfirmPasswordVisible(true)
                                }
                            />
                        )}
                    </span>
                    {errors.confirmPassword?.type === "required" && (
                        <p className='form-error'>Old Password is required.</p>
                    )}
                    {errors.confirmPassword?.type === "pattern" && (
                        <p className='form-error'>
                            Must Be Contains Uppercase, Lowercase Letters,
                            Numbers, Special Characters And Length Is Greater
                            Than 8 Character And Less Then 16 Character.
                        </p>
                    )}
                        <p className='form-error'>{isPasswordMismatch}</p>
                </div>
                <button type="submit" className='primary'>Save</button>
            </form>
        </div>
    );
};

export default ChangePasswordPopUp;
