export const initialState = {
  isLoggedIn:
    localStorage.getItem("token") || sessionStorage.getItem("token")
      ? true
      : false,
  profile_img:
    localStorage.getItem("profile_img") ||
    sessionStorage.getItem("profile_img"),
  loginModalShow: false,
  forgotPasswordModalShow: false,
  showNavCanvas: false,
  addToCartModalShow: false,
  documentsModalShow: false,
  cartQuestionsModalShow: false,
  cartQuestionsAnswers: {},
  changePasswordModalShow: false,
  createSupportTicketModalShow: false,
  exportPreviewModalShow: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_LOGIN_MODAL_SHOW":
      if (action.status === false) {
        sessionStorage.setItem("last_location", "");
      }
    
      return {
        ...state,
        loginModalShow: action.status,
      };

    case "SET_FORGOT_PASSWORD_MODAL_SHOW":
      return {
        ...state,
        forgotPasswordModalShow: action.status,
      };
    

    case "SET_USER_LOGGED_IN":
      return {
        ...state,
        isLoggedIn: action.status,
      };

    case "SET_PROFILE_IMAGE":
      return {
        ...state,
        profile_img: action.status,
      };
    case "SET_NAV_CANVAS_SHOW":
      return {
        ...state,
        showNavCanvas: action.status,
      };

    case "SET_ADD_TO_CART_MODAL_SHOW":
      return {
        ...state,
        addToCartModalShow: action.status,
      };
    case "SET_CART_QUESTIONS_MODAL_SHOW":
      return {
        ...state,
        cartQuestionsModalShow: action.status,
      };
    case "SET_CART_QUESTIONS_ANSWERS":
      // console.log(action.status);
      return {
        ...state,
        cartQuestionsAnswers: action.status,
      };
    case "SET_DOCUMENTS_MODAL_SHOW":
      return {
        ...state,
        documentsModalShow: action.status,
      };
    case "SET_CHANGE_PASSWORD_MODAL_SHOW":
      return {
        ...state,
        changePasswordModalShow: action.status,
      };
    case "SET_CREATE_SUPPORT_TICKET_MODAL_SHOW":
      return {
        ...state,
        createSupportTicketModalShow: action.status,
      };
    case "SET_EXPORT_PREVIEW_MODAL_SHOW":
      return {
        ...state,
        exportPreviewModalShow: action.status,
      };
    default:
      return state;
  }
}

export default reducer;
