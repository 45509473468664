import React, { useState } from "react";
import ProductTable from "./ProductTable";

import { ReactComponent as ArrowBlack } from "../../../Assets/icons/black-arrow.svg";
import "./products.scss";
import { useNavigate, useParams } from "react-router-dom";
import { GetProductsByCategory } from "../../../apiCall";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { CACHE_TIME } from "../../../Constants";

const Products = () => {
    const navigateTo = useNavigate();

    const [products, setProducts] = useState();
    const [categoryDetails, setCategoryDetails] = useState();
    const { id } = useParams();

    useQuery(
        ["products-by-category", id],
        () => GetProductsByCategory(id),
        {
            enabled: id ? true: false,
            // staleTime: STALE_TIME,
            cacheTime: CACHE_TIME,
            onSuccess: (data) => {
                // console.log(data);
                if (data.data.status[0].Error === "False") {
                    setProducts(data.data.value.products);
                    setCategoryDetails(data.data.value.category_details);
                }
            },
            onError: (err) => {
                // console.log(err, "ERROR");
                if (err.message) {
                    toast.error(err.message);
                } else {
                    toast.error("Something went wrong");
                }
            },
        }
    );
    return (
        <div className='products-main'>
            <span
                className='arrow primary-btn'
                onClick={() => navigateTo("/products-and-service")}>
                <ArrowBlack />
            </span>
            <h1>{categoryDetails?.title}</h1>
            <p>{categoryDetails?.description}</p>
            <ProductTable products={products} type={"products"} />
        </div>
    );
};

export default Products;
