import React, { useEffect, useRef, useState } from "react";
import AnimateSVG from "../../Assets/home/section1-animate.svg";
import SVG from "../../Assets/home/section1.svg";
// import { ReactComponent as Play } from "../../Assets/icons/play.svg";
import { ReactComponent as Call } from "../../Assets/icons/call.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";
// import LogoBg from "../../Assets/home/logo-bg.svg"
import Arrow from "../../Assets/icons/red-arrow.svg";
import AboutHero from "../../Assets/home/about-hero.svg";
// import ProductImg from "../../Assets/images/product.png";
// import CircleArrow from "../../Assets/icons/circle-arrow.svg";
import DownArrow from "../../Assets/icons/downArror.svg";
import Farmer from "../../Assets/home/farmer.svg";
import ContactImg from "../../Assets/home/contact.png";
import { ReactComponent as ArrowBlack } from "../../Assets/icons/black-arrow.svg";
import { useIsInViewport } from "../../utils/utils";
// import Triangle from "../../Assets/icons/triangle.svg"

// import ClientImg from "../../Assets/home/client-img.svg";
import "./home.scss";
import { useForm } from "react-hook-form";
import {
    GetAllProducts,
    GetCategoryList,
    GetClientDetails,
    GetHomeContent,
    SubmitContactRequest,
} from "../../apiCall";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { CACHE_TIME, domainName } from "../../Constants";
import CountUp from "react-countup";
import { useStateValue } from "../../StateProvider";
import CountryCodes from "../../CountryCodes.json"


// let slidesPerView = 5;
// if (window.innerWidth <= 550) {
//     slidesPerView = 4;
// }
// if (window.innerWidth <= 450) {
//     slidesPerView = 3.5;
// }
// if (window.innerWidth <= 350) {
//     slidesPerView = 3;
// }
let slidesPerView = 9;
if (window.innerWidth <= 350) {
    slidesPerView = 3;
} else if (window.innerWidth <= 450) {
    slidesPerView = 3.5;
} else if (window.innerWidth <= 550) {
    slidesPerView = 4;
} else if (window.innerWidth <= 650) {
    slidesPerView = 5;
} else if (window.innerWidth <= 750) {
    slidesPerView = 5.5;
} else if (window.innerWidth <= 850) {
    slidesPerView = 6;
} else if (window.innerWidth <= 950) {
    slidesPerView = 7;
} else if (window.innerWidth <= 1050) {
    slidesPerView = 8;
}

// const ProductCategories = [
//     "Registration and certification",
//     "Registration and certification",
//     "Registration and certification",
//     "Registration and certification",
//     "Registration and certification",
// ];
// const Products = [
//     {
//         title: "Registration and certification",
//         about: "Free strategy session",
//         img: ProductImg,
//     },
//     {
//         title: "Registration and certification",
//         about: "Free strategy session",
//         img: ProductImg,
//     },
// ];
// const clientReview = [
//     {
//         img: ClientImg,
//         name: "Shailesh",
//         review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//         company: "shaileshtech company",
//     },
//     {
//         img: ClientImg,
//         name: "Shailesh",
//         review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//         company: "shaileshtech company",
//     },
//     {
//         img: ClientImg,
//         name: "Shailesh",
//         review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//         company: "shaileshtech company",
//     },
// ];
const Home = () => {
    const [{ isLoggedIn }, dispatch] = useStateValue();
    const navigateTo = useNavigate();
    const [isProductCatOpen, setIsProductCatOpen] = useState(false);
    const [selectedProductCategory, setSelectedProductCategory] = useState(-1);
    const [homeContent, setHomeContent] = useState();
    const [clientDetails, setClientDetails] = useState();

    const containerRef = useRef();
    const scrollLeft = () => {
        containerRef.current.scrollLeft = Math.max(
            0,
            containerRef.current.scrollLeft - 350
        );
    };
    const scrollRight = () => {
        containerRef.current.scrollLeft = Math.min(
            containerRef.current.scrollWidth,
            containerRef.current.scrollLeft + 350
        );
    };
    const clientReviewRef = useRef();
    const scrollLeftReview = () => {
        clientReviewRef.current.scrollLeft = Math.max(
            0,
            clientReviewRef.current.scrollLeft -
                clientReviewRef.current.offsetWidth -
                10
        );
    };
    const scrollRightReview = () => {
        clientReviewRef.current.scrollLeft = Math.min(
            clientReviewRef.current.scrollWidth,
            clientReviewRef.current.scrollLeft +
                clientReviewRef.current.offsetWidth +
                10
        );
    };

    const section2Ref = useRef();
    const section3Ref = useRef();
    const section4Ref = useRef();
    const section5Ref = useRef();
    const section6Ref = useRef();
    const section7Ref = useRef();
    const section8Ref = useRef();
    const isSection2InViewport = useIsInViewport(section2Ref);
    const isSection3InViewport = useIsInViewport(section3Ref);
    const isSection4InViewport = useIsInViewport(section4Ref);
    const isSection5InViewport = useIsInViewport(section5Ref);
    const isSection6InViewport = useIsInViewport(section6Ref);
    const isSection7InViewport = useIsInViewport(section7Ref);
    const isSection8InViewport = useIsInViewport(section8Ref);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();
    const submitForm = async (data) => {
        data.name = data.first_name + " " + data.last_name;
        const Re = await SubmitContactRequest(data);
        // console.log(Re);

        if (Re.data?.status[0].Error === "False") {
            toast.success("We have received your query and will get back to you soon.");
            reset();
        } else {
            if (Re.data?.status) {
                toast.error(
                    Re.data.status[0].Message
                        ? Re.data.status[0].Message
                        : Re.data.status[0].ResponseMessage
                );
            } else {
                toast.error("Something went wrong, Please try again.");
            }
        }
    };

    const showLoginModel = () => {
        dispatch({
            type: "SET_LOGIN_MODAL_SHOW",
            status: true,
        });
    };

    useEffect(() => {
        if (isSection2InViewport) {
            section2Ref.current.classList.add("animate");
        }
        if (isSection3InViewport) {
            section3Ref.current.classList.add("animate");
        }
        if (isSection4InViewport) {
            section4Ref.current.classList.add("animate");
        }
        if (isSection5InViewport) {
            section5Ref.current.classList.add("animate");
        }
        if (isSection6InViewport) {
            section6Ref.current.classList.add("animate");
        }
        if (isSection7InViewport) {
            section7Ref.current.classList.add("animate");
        }
        if (isSection8InViewport) {
            section8Ref.current.classList.add("animate");
        }
    }, [
        isSection2InViewport,
        isSection3InViewport,
        isSection4InViewport,
        isSection5InViewport,
        isSection6InViewport,
        isSection7InViewport,
        isSection8InViewport,
    ]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const { isLoading: home_content_loading } = useQuery(
        ["get-home-content"],
        () => GetHomeContent(),
        {
            // staleTime: STALE_TIME,
            cacheTime: CACHE_TIME,
            onSuccess: (data) => {
                if (data?.data?.status[0].Error === "False") {
                    setHomeContent(data.data.value);
                }
            },
            onError: (err) => {
                if (err.message) {
                    toast.error(err.message);
                } else {
                    toast.error("Something went wrong");
                }
            },
        }
    );

    const { isLoading: client_loading } = useQuery(
        ["get-client"],
        () => GetClientDetails(),
        {
            // staleTime: STALE_TIME,
            cacheTime: CACHE_TIME,
            onSuccess: (data) => {
                if (data?.data?.status[0].Error === "False") {
                    setClientDetails(data.data.value);
                }
            },
            onError: (err) => {
                if (err.message) {
                    toast.error(err.message);
                } else {
                    toast.error("Something went wrong");
                }
            },
        }
    );
    const { isLoading: categories_loading } = useQuery(
        ["get-categories"],
        () => GetCategoryList(),
        {
            // staleTime: STALE_TIME,
            cacheTime: CACHE_TIME,
            onSuccess: (data) => {
                if (data.data?.status[0].Error === "False") {
                    setCategories(data.data.value);

                    const min_id = data?.data?.value?.reduce(
                        (min_id, cat) => Math.min(min_id, cat.id),
                        Number.MAX_SAFE_INTEGER
                    );
                    setSelectedProductCategory(min_id);
                }
            },
            onError: (err) => {
                if (err.message) {
                    toast.error(err.message);
                } else {
                    toast.error("Something went wrong");
                }
            },
        }
    );
    const { isLoading: all_products_loading } = useQuery(
        ["all-products"],
        () => GetAllProducts(),
        {
            // staleTime: STALE_TIME,
            cacheTime: CACHE_TIME,
            onSuccess: (data) => {
                if (data.data?.status[0].Error === "False") {
                    setProducts(data.data.value);
                }
            },
            onError: (err) => {
                if (err.message) {
                    toast.error(err.message);
                } else {
                    toast.error("Something went wrong");
                }
            },
        }
    );

    return (
        <div className='home-main'>
            {/****************************************** Section 1 ******************************************/}
            <section className='section section-1'>
                <div className='section-container section-1-container'>
                    {home_content_loading ? (
                        <div className='component-loading main-text-loading-cmp'></div>
                    ) : (
                        <div className='main-text'>
                            <h1 className='line-1 section-heading'>
                                <span className='vertical-text'>
                                    From idea to reality
                                </span>
                                Turning strategies
                                {/* <br /> into {" "}
              <span className="bold">success</span>{" "} */}
                            </h1>
                            <h2 className='bold line-2'>
                                into <span>Success</span>
                            </h2>
                            {/* Our team with our clients helps them in achieving sustainable and inclusive growth */}
                            <p>{homeContent?.home_description}</p>
                            <div>
                                <span className='call-btn'>
                                    <button
                                        className='primary-btn'
                                        onClick={() =>
                                            isLoggedIn
                                                ? navigateTo("/schedule-call")
                                                : showLoginModel()
                                        }>
                                        Schedule call with us
                                    </button>
                                    <span className='call-icon'>
                                        <Call />
                                    </span>
                                </span>
                                <span className='my-svg'>
                                    <img src={SVG} alt='' />
                                    <img
                                        className='animate-SVG'
                                        src={AnimateSVG}
                                        alt=''
                                    />
                                </span>
                            </div>
                            {/* <span className='gif'>
                            <iframe
                                className='HTML-Embed'
                                width='264'
                                height='50'
                                src='https://www.youtube.com/embed/UiiRSCpKHHQ?autoplay=1&mute=1&loop=1'
                                title='YouTube video player'
                                frameBorder='0'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen></iframe>
                            <a
                                href='https://www.youtube.com/watch?v=UiiRSCpKHHQ'
                                target='__blank'
                                className='play'>
                                <Play />
                            </a>
                        </span> */}
                        </div>
                    )}
                    <div className='statistics'>
                        {home_content_loading ? (
                            <div className='loading-statistics-container'>
                                <div className='component-loading statistics-loading-cmp'></div>
                                <div className='component-loading statistics-loading-cmp'></div>
                                <div className='component-loading statistics-loading-cmp'></div>
                            </div>
                        ) : (
                            homeContent?.statistics.map((stat, indx) => (
                                <div key={"stat" + indx}>
                                    <span className='number'>
                                        <CountUp
                                            start={Math.max(
                                                stat.number - 100,
                                                0
                                            )}
                                            end={stat.number}
                                            duration={2}
                                            useEasing={false}
                                            // delay={1}
                                        />
                                        +
                                    </span>
                                    <span className='text'>{stat.title}</span>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </section>

            {/****************************************** Section 2 ******************************************/}
            {clientDetails && (
                <div className='section-2 section'>
                    <div className='section-2-container section-container'>
                        {client_loading ? (
                            <div className='component-loading loading-section-heading'></div>
                        ) : (
                            <h1 className='section-heading'>
                                <span className='vertical-text'>
                                    Trusted By
                                </span>
                                <p>Clients & Companies</p>{" "}
                                <p className='bold' ref={section2Ref}>
                                    We Work With
                                </p>{" "}
                            </h1>
                        )}
                        <div className='client-container'>
                            <div className='client-logo-swiper'>
                                {client_loading ? (
                                    <div className='loading-client-logo-container'>
                                        <div className='component-loading loading-client'></div>
                                        <div className='component-loading loading-client'></div>
                                        <div className='component-loading loading-client'></div>
                                        <div className='component-loading loading-client'></div>
                                        <div className='component-loading loading-client'></div>
                                        <div className='component-loading loading-client'></div>
                                        <div className='component-loading loading-client'></div>
                                        <div className='component-loading loading-client'></div>
                                        <div className='component-loading loading-client'></div>
                                        <div className='component-loading loading-client'></div>
                                    </div>
                                ) : (
                                    <Swiper
                                        spaceBetween={24}
                                        centeredSlides={true}
                                        autoplay={{
                                            delay: 500,
                                            disableOnInteraction: false,
                                        }}
                                        loopedSlides={true}
                                        loop={true}
                                        modules={[Autoplay]}
                                        className='mySwiper'
                                        slidesPerView={slidesPerView}
                                        // slidesPerGroup={3}
                                        speed={500}>
                                        {clientDetails?.map((client) => (
                                            <SwiperSlide
                                                key={"client" + client.id}>
                                                <div className='client'>
                                                    <img
                                                        src={
                                                            domainName +
                                                            "/uploads/" +
                                                            client.company_logo
                                                        }
                                                        alt=''
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/****************************************** Section 3 ******************************************/}
            {homeContent?.stratergy.length > 0 && (
                <section className='section-3 section'>
                    <div className='section-3-container section-container'>
                        {home_content_loading ? (
                            <div className='component-loading loading-section-heading'></div>
                        ) : (
                            <h1 className='section-heading'>
                                <span className='vertical-text'>Expertise</span>
                                <p>Our</p>{" "}
                                <p className='bold' ref={section3Ref}>
                                    Strategies
                                </p>{" "}
                            </h1>
                        )}
                        {home_content_loading ? (
                            <div className='loading-stratergies stratergies'>
                                <div className='component-loading loading-strat'></div>
                                <div className='component-loading loading-strat'></div>
                                <div className='component-loading loading-strat'></div>
                            </div>
                        ) : (
                            <div className='stratergies'>
                                {homeContent?.stratergy.map((strat, indx) => (
                                    <div
                                        className='stratergy'
                                        key={"strat" + indx}>
                                        <span className='title bold'>
                                            <p className='red'>{indx + 1}.</p>
                                            <p>{strat.title}</p>
                                        </span>
                                        <p className='content'>
                                            {strat.description}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </section>
            )}
            {!home_content_loading && <div className='logo-bg'></div>}
            {/****************************************** Section 4 ******************************************/}
            <section className='section section-4'>
                <div className='section-4-container section-container'>
                    {home_content_loading ? (
                        <div className='component-loading loading-about-us-img'></div>
                    ) : (
                        <img src={AboutHero} alt='' />
                    )}
                    <div className='content'>
                        {home_content_loading ? (
                            <div className='component-loading loading-section-heading'></div>
                        ) : (
                            <h1 className='section-heading'>
                                <span className='vertical-text'>
                                    Who we are
                                </span>
                                About <br />{" "}
                                <span className='bold' ref={section4Ref}>
                                    Aapka CFO
                                </span>
                            </h1>
                        )}
                        {home_content_loading ? (
                            <div className='component-loading loading-about-us-content'></div>
                        ) : (
                            <>
                                <p className='text'>
                                    {homeContent?.about_aapkacfo}
                                </p>
                                <span className='call-btn'>
                                    <button
                                        className='primary-btn'
                                        onClick={() =>
                                            isLoggedIn
                                                ? navigateTo("/schedule-call")
                                                : showLoginModel()
                                        }>
                                        Schedule call with us
                                    </button>
                                    <span className='call-icon'>
                                        <Call />
                                    </span>
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </section>
            {/****************************************** Section 5 ******************************************/}
            <section className='section section-5'>
                <div className='section-container section-5-container'>
                    <div className='header'>
                        {(categories_loading && all_products_loading) ? (
                            <div className='component-loading loading-section-heading'></div>
                        ) : (
                            <h1 className='section-heading'>
                                <span className='vertical-text'>
                                    Who we are
                                </span>
                                <p>Our </p>{" "}
                                <p className='bold' ref={section5Ref}>
                                    Products
                                </p>
                            </h1>
                        )}
                        {!(categories_loading && all_products_loading) && (
                            <div className='arrows'>
                                <span
                                    className='arrow primary-btn'
                                    onClick={scrollLeft}>
                                    <ArrowBlack />
                                </span>
                                <span
                                    className='arrow primary-btn reverse'
                                    onClick={scrollRight}>
                                    <ArrowBlack />
                                </span>
                            </div>
                        )}
                    </div>
                    {(categories_loading && all_products_loading) ? (
                        <div className='loading-categories-drop-down component-loading'></div>
                    ) : (
                        <div
                            className={`categories-drop-down ${
                                isProductCatOpen === true ? "open" : ""
                            }`}>
                            <div
                                className='selected'
                                onClick={() =>
                                    setIsProductCatOpen((pre) => !pre)
                                }>
                                <p>
                                    {selectedProductCategory !== -1
                                        ? categories.filter(
                                              (cat) =>
                                                  cat.id ===
                                                  selectedProductCategory
                                          )[0]?.title
                                        : "All Categories"}
                                </p>
                                <img src={DownArrow} alt='' />
                            </div>
                            <div className='categories'>
                                <div
                                    onClick={() => {
                                        setSelectedProductCategory(-1);
                                        setIsProductCatOpen(false);
                                    }}
                                    className={`category`}>
                                    All Categories
                                </div>
                                {categories
                                    ?.sort((cat1, cat2) => cat1.id - cat2.id)
                                    ?.map((cat) => (
                                        <div
                                            key={"cat" + cat.id}
                                            onClick={() => {
                                                setSelectedProductCategory(
                                                    cat.id
                                                );
                                                setIsProductCatOpen(false);
                                            }}
                                            className={`category ${
                                                selectedProductCategory ===
                                                cat.id
                                                    ? "selected-category"
                                                    : ""
                                            }`}>
                                            {cat.title}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                    <div className='product-container'>
                        {(categories_loading && all_products_loading) ? (
                            <div className='product-categories component-loading'></div>
                        ) : (
                            <div className='product-categories'>
                                {categories
                                    ?.sort((cat1, cat2) => cat1.id - cat2.id)
                                    ?.map((cat) => (
                                        <div
                                            key={"cat" + cat.id}
                                            className={`prod-category ${
                                                selectedProductCategory ===
                                                cat.id
                                                    ? "selected-category"
                                                    : ""
                                            }`}
                                            onClick={() => {
                                                containerRef.current.scrollLeft = 0;
                                                if (
                                                    selectedProductCategory ===
                                                    cat.id
                                                ) {
                                                    setSelectedProductCategory(
                                                        -1
                                                    );
                                                } else {
                                                    setSelectedProductCategory(
                                                        cat.id
                                                    );
                                                    setIsProductCatOpen(false);
                                                }
                                            }}>
                                            <p>{cat.title}</p>
                                            <img src={Arrow} alt='' />
                                        </div>
                                    ))}
                            </div>
                        )}
                        <div className='products-container' ref={containerRef}>
                            {(categories_loading && all_products_loading) ? (
                                <>
                                    <div className='product component-loading'></div>
                                    <div className='product component-loading'></div>
                                    <div className='product component-loading'></div>
                                    <div className='product component-loading'></div>
                                </>
                            ) : (
                                products
                                    ?.filter(
                                        (prod) =>
                                            prod.category_id ===
                                                selectedProductCategory ||
                                            selectedProductCategory === -1
                                    )
                                    .sort((prod1, prod2) => {
                                        if (
                                            prod1.category_id !==
                                            prod2.category_id
                                        ) {
                                            return (
                                                prod1.category_id -
                                                prod2.category_id
                                            );
                                        } else {
                                            return prod1.id - prod2.id;
                                        }
                                    })
                                    .map((prod) => (
                                        <div
                                            key={"prod" + prod.id}
                                            className='product'>
                                            <img
                                                src={
                                                    domainName +
                                                    prod.product_img
                                                }
                                                alt=''
                                                onClick={() =>
                                                    navigateTo(
                                                        "/product-details/" +
                                                            prod.id
                                                    )
                                                }
                                            />
                                            <div className='content'>
                                                <div
                                                    className='text'
                                                    onClick={() =>
                                                        navigateTo(
                                                            "/product-details/" +
                                                                prod.id
                                                        )
                                                    }>
                                                    <p className='title bold'>
                                                        {prod.title}
                                                    </p>
                                                    <p className='about'>
                                                        {prod.description}
                                                    </p>
                                                </div>
                                                <div className='button'>
                                                    <button
                                                        className='primary-btn'
                                                        onClick={() =>
                                                            isLoggedIn
                                                                ? navigateTo(
                                                                      `/schedule-call?serviceId=${prod.category_id}&&productId=${prod.id}`
                                                                  )
                                                                : showLoginModel()
                                                        }>
                                                        Schedule call with us
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {/****************************************** Section 6 ******************************************/}
            {!home_content_loading && <section className='section section-6'>
                <div className='section-container section-6-container'>
                    <img src={Farmer} alt='' />
                    <div className='text-container'>
                        <h1 className='section-heading'>
                            <span className='vertical-text'>Helping</span>
                            Farmers at the cost <br />{" "}
                            <span className='bold' ref={section6Ref}>
                                of only ₹1{" "}
                            </span>
                        </h1>
                        <p className='text'>
                            {homeContent?.farmer_description}
                        </p>
                        <span className='call-btn'>
                            <button
                                className='primary-btn'
                                onClick={() =>
                                    isLoggedIn
                                        ? navigateTo("/schedule-call")
                                        : showLoginModel()
                                }>
                                Schedule call with us
                            </button>
                            <span className='call-icon'>
                                <Call />
                            </span>
                        </span>
                    </div>
                </div>
            </section>}
            {/****************************************** Section 7 ******************************************/}
            {!home_content_loading && <section className='section section-7'>
                <div className='section-container section-7-container'>
                    <h1 className='section-heading'>
                        <span className='vertical-text'>Join Us</span>
                        Connect{" "}
                        <span className='bold' ref={section7Ref}>
                            With Us{" "}
                        </span>
                    </h1>
                    <div className='connect-form'>
                        <img src={ContactImg} alt='' />
                        <form>
                            <div className='input-grp'>
                                <div className='input'>
                                    <input
                                        type='text'
                                        name='first_name'
                                        placeholder='First Name'
                                        {...register("first_name", {
                                            required: true,
                                            maxLength: 35,
                                        })}
                                    />
                                    {errors.first_name?.type === "required" && (
                                        <p className='form-error'>
                                            First name is required.
                                        </p>
                                    )}
                                    {errors.first_name?.type ===
                                        "maxLength" && (
                                        <p className='form-error'>
                                            Length of first name can not be more
                                            than 35.
                                        </p>
                                    )}
                                </div>
                                <div className='input'>
                                    <input
                                        type='text'
                                        name='last_name'
                                        placeholder='Last Name'
                                        {...register("last_name", {
                                            required: true,
                                            maxLength: 35,
                                        })}
                                    />
                                    {errors.last_name?.type === "required" && (
                                        <p className='form-error'>
                                            Last name is required.
                                        </p>
                                    )}
                                    {errors.last_name?.type === "maxLength" && (
                                        <p className='form-error'>
                                            Length of last name can not be more
                                            than 35.
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className='input-grp'>
                                <div className='input'>
                                    <input
                                        type='email'
                                        name='email'
                                        placeholder='Email'
                                        {...register("email", {
                                            required: true,
                                        })}
                                    />
                                    {errors.email?.type === "required" && (
                                        <p className='form-error'>
                                            Email is required.
                                        </p>
                                    )}
                                </div>
                                <div className='call-grp'>
                                    <div className='input'>
                                        <select
                                            name=''
                                            id=''
                                            defaultValue={'+91'}
                                            {...register("country_code")}>
                                            {CountryCodes.sort().map(code => <option value={code.dial_code}>{`${code.code} (${code.dial_code})`}</option>)}
{/* 
                                            <option value='+91'>+91</option>
                                            <option value='+92'>+92</option>
                                            <option value='+93'>+93</option>
                                            <option value='+94'>+94</option>
                                            <option value='+95'>+95</option> */}
                                        </select>
                                        <input
                                            type='number'
                                            name='phone_number'
                                            placeholder='Phone Number'
                                            {...register("phone_number", {
                                                required: true,
                                            })}
                                        />
                                    </div>
                                    {errors.phone_number?.type ===
                                        "required" && (
                                        <p className='form-error'>
                                            Phone number is required.
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className='input'>
                                <input
                                    type='text'
                                    placeholder='Message'
                                    {...register("message", {
                                        required: true,
                                    })}
                                />
                                {errors.message?.type === "required" && (
                                    <p className='form-error'>
                                        Message field can not be empty.
                                    </p>
                                )}
                            </div>
                            <button
                                className='primary-btn'
                                onClick={handleSubmit(submitForm)}>
                                Send message
                            </button>
                        </form>
                    </div>
                </div>
            </section>}
            {/****************************************** Section 8 ******************************************/}
            {!home_content_loading && homeContent?.reviews?.length > 0 && (
                <section className='section section-8'>
                    <div className='section-container section-8-container'>
                        <h1 className='section-heading'>
                            <span className='vertical-text'>Testimonials</span>
                            What Our <br />{" "}
                            <span className='bold' ref={section8Ref}>
                                Client Say's{" "}
                            </span>
                        </h1>
                        <div className='arrows'>
                            <span
                                className='arrow primary-btn'
                                onClick={scrollLeftReview}>
                                <ArrowBlack />
                            </span>
                            <span
                                className='arrow primary-btn reverse'
                                onClick={scrollRightReview}>
                                <ArrowBlack />
                            </span>
                        </div>
                        <div className='client-reviews' ref={clientReviewRef}>
                            {homeContent?.reviews?.map((review) => (
                                <div
                                    className='review'
                                    key={"client_review" + review.id}>
                                    <img
                                        src={
                                            domainName +
                                            "/uploads/" +
                                            review.image
                                        }
                                        alt=''
                                    />
                                    <div className='text'>
                                        <p>{review.review}</p>
                                        <div className='author'>
                                            <p className='bold'>
                                                {review.reviewer_name}
                                            </p>
                                            <p className='company-name'>
                                                {review.company_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            )}
            {/****************************************** Section 9 ******************************************/}
            <section className='section section-9'>
                <div className='section-container section-9-container'>
                    {home_content_loading ? <div className="component-loading we-loading"></div> : <p>We</p>}
                    {home_content_loading ? <div className="component-loading we-text-loading"></div> : <div className='text'>
                        <h1>truly believe in,</h1>
                        <h1>
                            <span className='bold'>DHANDHA</span> AAP DEKHIYE
                        </h1>
                        <h1>
                            BAKI SAB <span className='bold'>HUM DEKHENGE</span>
                        </h1>
                    </div>}
                </div>
            </section>
        </div>
    );
};

export default Home;
