import axios from 'axios'
import { domainName, api } from '../Constants'

const client = axios.create({baseURL: domainName})

export const request = ({...options}) =>{
    console.log(options)

    let token = sessionStorage.getItem("token") || localStorage.getItem("token")
    if(options.url === api.generate_refresh_token){
        token = sessionStorage.getItem("refresh_token") || localStorage.getItem("refresh_token")
    }
    client.defaults.headers.common.Authorization = `Bearer ${token}`

    const onSuccess = response => response
    const onError = error =>{
        return error
    }

    return client(options).then(onSuccess).catch(onError)
}