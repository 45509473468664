import React, { useState } from "react";
import { useQuery } from "react-query";
import { GetAllProducts, GetCategoryList } from "../../apiCall";
import { CACHE_TIME } from "../../Constants";
import { toast } from "react-toastify";
import "./welcome.scss";
import WelcomeScreen from "./components/WelcomeScreen";
import Introduction from "./components/Introduction";
import CategoriesSelect from "./components/CategoriesSelect";
import ProductsSelect from "./components/ProductsSelect";
import Questions from "./components/Questions";

const Welcome = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [step, setStep] = useState(1)

    useQuery(["get-categories"], () => GetCategoryList(), {
        // staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onSuccess: (data) => {
            // // console.log(data);
            if (data.data?.status[0].Error === "False") {
                setCategories(data?.data.value);
            }
        },
        onError: (err) => {
            // console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });
    useQuery(["products-all-products"], () => GetAllProducts(), {
        // staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onSuccess: (data) => {
            // // console.log(data);
            if (data.data?.status[0].Error === "False") {
                setProducts(data.data.value);
            }
        },
        onError: (err) => {
            // console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });
    return (
        <div className='welcome-main'>
            {step === 1 && <WelcomeScreen setStep={setStep}/>}
            {step === 2 && <Introduction setStep={setStep}/>}
            {step === 3 && <CategoriesSelect selectedCategories={selectedCategories} setSelectedCategories={setSelectedCategories} categories={categories} setStep={setStep}/>}
            {step === 4 && <ProductsSelect selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} products={products?.filter(prod => selectedCategories.includes(prod.category_id))} setStep={setStep}/>}
            {step === 5 && <Questions selectedProducts={selectedProducts} selectedCategories={selectedCategories} setStep={setStep}/>}
        </div>
    );
};

export default Welcome;
