import React, { useCallback, useEffect, useRef, useState } from "react";

import "./sideBar.scss";
// import { ForgotPasswordModal } from "../../Modals";
import { useStateValue } from "../../../StateProvider";
import { ReactComponent as SideBarIcon } from "../../../Assets/icons/sidebar.svg";
import { ReactComponent as EditIcon } from "../../../Assets/icons/edit.svg";
import { ReactComponent as CorrectIcon } from "../../../Assets/icons/check-single.svg";
import { ReactComponent as MoreIcon } from "../../../Assets/icons/more.svg";
import { ReactComponent as EditCopyIcon } from "../../../Assets/icons/edit-01.svg";
import { ReactComponent as DeleteIcon } from "../../../Assets/icons/delete.svg";
import {
    ChangeChatName,
    CreateChat,
    GetChats,
    DeleteChat,
    GenerateRefreshToken,
} from "../../../apiCall";
// import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
    format,
    isToday,
    isYesterday,
    isWithinInterval,
    subDays,
    startOfMonth,
} from "date-fns";
import { toast } from "react-toastify";

const SideBar = ({
    activeChat,
    setActiveChat,
    setSelectedResponseStyle,
    setLastText,
    chats,
    setChats,
    showSideBar,
    setShowSideBar,
}) => {
    // console.log(chats);
    const [{ isLoggedIn }, dispatch] = useStateValue();
    const [editChat, setEditChat] = useState();
    const [newName, setNewName] = useState("");
    const navigateTo = useNavigate();

    const [seperatedChats, setSeperatedChats] = useState({});
    const [showChatOption, setShowChatOption] = useState();
    const ref = useRef();
    const focusInput = () => {
        setTimeout(() => {
            ref?.current.focus();
            // console.log(ref);
        }, 10);
    };

    const openLoginForm = useCallback(() => {
        navigateTo("/");
        setActiveChat();
        setSelectedResponseStyle();
        setLastText();
    }, [navigateTo, setActiveChat, setLastText, setSelectedResponseStyle]);

    const createNewChat = useCallback(async () => {
        if (!isLoggedIn) {
            openLoginForm();
            return;
        }
        const re = await CreateChat();
        // console.log("CREATE CHAT", re);

        if (re?.response?.data?.detail) {
            if (
                re?.response?.data?.detail ===
                "Unauthenticated User, Please login first."
            ) {
                const refreshRe = await GenerateRefreshToken();

                console.log(refreshRe, "REFRESH RE");

                if (refreshRe?.data?.access_token) {
                    localStorage.setItem(
                        "refresh_token",
                        refreshRe?.data?.refresh_token
                    );
                    localStorage.setItem(
                        "token",
                        refreshRe?.data?.access_token
                    );
                    createNewChat();
                } else {
                    localStorage.clear();
                    dispatch({
                        type: "SET_USER_LOGGED_IN",
                        status: false,
                      });
                }
            } else {
                toast.error(re?.response?.data?.detail);
            }
        } else if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                // setShowSideBar(true)
                setChats((pre) => [re.data.value, ...pre]);
                setActiveChat(re.data.value.id);

                setSelectedResponseStyle(re.data.value.response_style);
                if (window.innerWidth <= 1150) {
                    setShowSideBar(false);
                }
            }
        }
    }, [
        isLoggedIn,
        openLoginForm,
        setSelectedResponseStyle,
        setActiveChat,
        setChats,
        setShowSideBar,
        dispatch,
    ]);

    const handleChatNameChange = async (id) => {
        if (!newName) {
            toast.error("Chatname can not be empty");
            return;
        }
        const re = await ChangeChatName(id, newName);
        // console.log("CREATE CHAT", re);

        if (re?.response?.data?.detail) {
            if (
                re?.response?.data?.detail ===
                "Unauthenticated User, Please login first."
            ) {
                const refreshRe = await GenerateRefreshToken();

                console.log(refreshRe, "REFRESH RE");

                if (refreshRe?.data?.access_token) {
                    localStorage.setItem(
                        "refresh_token",
                        refreshRe?.data?.refresh_token
                    );
                    localStorage.setItem(
                        "token",
                        refreshRe?.data?.access_token
                    );
                    handleChatNameChange(id);
                } else {
                    localStorage.clear();
                    dispatch({
                        type: "SET_USER_LOGGED_IN",
                        status: false,
                      });
                }
            } else {
                toast.error(re?.response?.data?.detail);
            }
        } else if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                setChats((pre) =>
                    pre.map((chat) =>
                        chat.id === id ? { ...chat, chat_name: newName } : chat
                    )
                );
            }
        }
    };
    const deleteChat = async (id) => {
        const re = await DeleteChat(id);
        // console.log("DELETE CHAT", re);

        if (re?.response?.data?.detail) {
            if (
                re?.response?.data?.detail ===
                "Unauthenticated User, Please login first."
            ) {
                const refreshRe = await GenerateRefreshToken();

                console.log(refreshRe, "REFRESH RE");

                if (refreshRe?.data?.access_token) {
                    localStorage.setItem(
                        "refresh_token",
                        refreshRe?.data?.refresh_token
                    );
                    localStorage.setItem(
                        "token",
                        refreshRe?.data?.access_token
                    );
                    deleteChat(id);
                } else {
                    localStorage.clear();
                    dispatch({
                        type: "SET_USER_LOGGED_IN",
                        status: false,
                      });
                }
            } else {
                toast.error(re?.response?.data?.detail);
            }
        } else if (re.status !== 200) {
            toast.error("Something went wrong, Please reload the page");
        } else {
            if (re.data.status[0].Error === "True") {
                toast.error(re.data.status[0].ResponseMessage);
            } else {
                toast.success("Chat deleted successfully.");
                setShowChatOption();
                setChats((pre) => pre.filter((chat) => chat.id !== id));
                if (activeChat === id) {
                    if (chats?.length > 0) {
                        setActiveChat(chats[0]?.id);
                    } else {
                        setActiveChat();
                    }
                }
            }
        }
    };
    const handleInputBlur = () => {
        setTimeout(() => {
            setNewName("");
            setEditChat();
        }, 1000);
    };
    useEffect(() => {
        createNewChat();

        const getData = async () => {
            const re = await GetChats();
            // console.log("GET CHATS", re.data?.value, re.status);

            if (re?.response?.data?.detail) {
                if (
                    re?.response?.data?.detail ===
                    "Unauthenticated User, Please login first."
                ) {
                    const refreshRe = await GenerateRefreshToken();

                    console.log(refreshRe, "REFRESH RE");

                    if (refreshRe?.data?.access_token) {
                        localStorage.setItem(
                            "refresh_token",
                            refreshRe?.data?.refresh_token
                        );
                        localStorage.setItem(
                            "token",
                            refreshRe?.data?.access_token
                        );
                        getData();
                    } else {
                        localStorage.clear();
                        dispatch({
                            type: "SET_USER_LOGGED_IN",
                            status: false,
                          });
                    }
                } else {
                    toast.error(re?.response?.data?.detail);
                }
            } else if (re.status !== 200) {
                toast.error("Something went wrong, Please reload the page");
            } else {
                if (re.data?.status[0].Error === "True") {
                    toast.error(re.data?.status[0].ResponseMessage);
                } else {
                    setChats(re.data?.value);
                    setActiveChat(re.data?.value[0]?.id);
                    setSelectedResponseStyle(re.data?.value[0]?.response_style);

                    if (re.data?.value.length === 0) {
                        createNewChat();
                    }
                }
            }
        };
        if (isLoggedIn) {
            getData();
        }
    }, [
        isLoggedIn,
        createNewChat,
        setActiveChat,
        setSelectedResponseStyle,
        setChats,
        dispatch,
    ]);

    useEffect(() => {
        if (window.innerWidth <= 1150) {
            setShowSideBar(false);
        }
    }, [activeChat, setShowSideBar]);

    useEffect(() => {
        const todayChats = [];
        const yesterdayChats = [];
        const last7DaysChats = [];
        const previous_7_days = [];
        const monthlyChats = {};

        // console.log(chats);

        const now = new Date();
        const last7DaysStart = subDays(now, 7);
        const startOfCurrentMonth = startOfMonth(now);

        chats?.forEach((chat) => {
            const chatDate = new Date(chat.create_at);

            if (isToday(chatDate)) {
                todayChats.push(chat);
            } else if (isYesterday(chatDate)) {
                yesterdayChats.push(chat);
            } else if (
                isWithinInterval(chatDate, { start: subDays(now, 7), end: now })
            ) {
                last7DaysChats.push(chat);
            } else if (
                isWithinInterval(chatDate, {
                    start: startOfCurrentMonth,
                    end: last7DaysStart,
                })
            ) {
                previous_7_days.push(chat);
            } else {
                const monthYear = format(chatDate, "MMMM yyyy");
                if (!monthlyChats[monthYear]) {
                    monthlyChats[monthYear] = [];
                }
                monthlyChats[monthYear].push(chat);
            }
        });

        // console.log("today");
        // console.log(todayChats);
        // console.log("yesterday");
        // console.log(yesterdayChats);
        // console.log("this week");
        // console.log(last7DaysChats);
        // console.log("previous 7");
        // console.log(previous_7_days);
        // console.log("monthly");
        // console.log(monthlyChats);

        setSeperatedChats({
            Today: todayChats,
            Yesterday: yesterdayChats,
            "Last 7 days": last7DaysChats,
            "Previous than 7 days": previous_7_days,
        });

        Object.keys(monthlyChats)?.forEach((key) => {
            setSelectedResponseStyle((pre) => {
                return { ...pre, key: monthlyChats[key] };
            });
        });
    }, [chats, setSelectedResponseStyle]);

    const handleLogOut = () => {
        sessionStorage.clear();
        localStorage.clear();
        setActiveChat();
        setSelectedResponseStyle();
        setChats();
        setLastText();
        dispatch({
            type: "SET_USER_LOGGED_IN",
            status: false,
        });
        toast.success("Logged out success fully!!!");
    };
    return (
        <>
            <div
                className={`side-bar-main ${
                    showSideBar ? "" : "hide-sidebar"
                }`}>
                {showChatOption && (
                    <div
                        className='backdrop'
                        onClick={() => setShowChatOption()}></div>
                )}
                <div className='chats-container'>
                    <div className='head'>
                        <SideBarIcon
                            onClick={() => setShowSideBar((pre) => !pre)}
                        />
                        <EditIcon onClick={createNewChat} />
                        {/* <p className='bold'>Create New Chat</p> */}
                    </div>
                    <div className='chats-list'>
                        {Object.keys(seperatedChats).map((key) => {
                            return seperatedChats[key]?.length > 0 ? (
                                <div className='time-container' key={key}>
                                    <p className='time'>{key}</p>
                                    {seperatedChats[key]?.map((chat) => (
                                        <div
                                            className={`chat ${
                                                activeChat === chat.id
                                                    ? "active"
                                                    : ""
                                            }`}
                                            key={"chat-" + chat.id}>
                                            {editChat === chat.id ? (
                                                <input
                                                    ref={ref}
                                                    type='text'
                                                    className={"edit"}
                                                    value={newName}
                                                    onBlur={handleInputBlur}
                                                    onFocus={() =>
                                                        setNewName(
                                                            chat.chat_name
                                                        )
                                                    }
                                                    onChange={(e) =>
                                                        setNewName(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <p
                                                    onClick={() => {
                                                        setActiveChat(chat.id);
                                                        setSelectedResponseStyle(
                                                            chat.response_style
                                                        );
                                                    }}>
                                                    {chat?.chat_name
                                                        ? chat.chat_name
                                                        : "New Chat"}
                                                </p>
                                            )}
                                            {editChat === chat.id ? (
                                                <CorrectIcon
                                                    onClick={() =>
                                                        handleChatNameChange(
                                                            chat.id
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <MoreIcon
                                                    className='more-icon'
                                                    onClick={() => {
                                                        setShowChatOption(
                                                            chat.id
                                                        );
                                                    }}
                                                />
                                            )}
                                            {chat.id === showChatOption && (
                                                <div className='chat-options'>
                                                    <span
                                                        onClick={() => {
                                                            setEditChat(
                                                                chat.id
                                                            );
                                                            focusInput();
                                                            setShowChatOption();
                                                        }}>
                                                        {" "}
                                                        <EditCopyIcon /> Edit
                                                    </span>
                                                    <span
                                                        onClick={() =>
                                                            deleteChat(chat.id)
                                                        }>
                                                        {" "}
                                                        <DeleteIcon /> Delete
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <></>
                            );
                        })}
                        {/* {} */}
                    </div>
                </div>
                <div className='login-details'>
                    {isLoggedIn ? (
                        <div className='details'>
                            <p>
                                Welcome!!{" "}
                                <span>
                                    {localStorage.getItem("name") ||
                                        sessionStorage.getItem("name")}
                                </span>
                            </p>
                            <button className='primary' onClick={handleLogOut}>
                                Logout
                            </button>
                        </div>
                    ) : (
                        <div className='details'>
                            <button className='primary' onClick={openLoginForm}>
                                Login
                            </button>
                        </div>
                    )}
                </div>
                {/* <LoginModal
                loginFormType={loginFormType}
                setFormType={setLoginFormType}
            /> */}
                {/* <ForgotPasswordModal /> */}
            </div>
            <div className='sidebar-icons'>
                <SideBarIcon onClick={() => setShowSideBar((pre) => !pre)} />
                <EditIcon onClick={createNewChat} />
                {/* <p className='bold'>Create New Chat</p> */}
            </div>
        </>
    );
};

export default SideBar;
