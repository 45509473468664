import React, { useEffect, useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper/modules";

import "./aboutUs.scss";
// import AboutImg from "../../Assets/images/about-us.png";
// import PersonImg from "../../Assets/images/avatar.png";
import { ReactComponent as Instagram } from "../../Assets/icons/instagram.svg";
import { ReactComponent as Facebook } from "../../Assets/icons/facebook.svg";
import { ReactComponent as LinkedIn } from "../../Assets/icons/linkedIn.svg";
import { ReactComponent as Twitter } from "../../Assets/icons/twitter.svg";
import { ReactComponent as ArrowBlack } from "../../Assets/icons/black-arrow.svg";

import { useNavigate } from "react-router-dom";
import {
    GetAboutContent,
    GetAllCaseStudies,
    GetClientDetails,
} from "../../apiCall";
import { toast } from "react-toastify";
import { CACHE_TIME, domainName } from "../../Constants";
import { useQuery } from "react-query";

// const peoples = [
//     {
//         img: PersonImg,
//         name: "Ronak Soni 1",
//         role: "CEO",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//     },
//     {
//         img: PersonImg,
//         name: "Ronak Soni 2",
//         role: "CEO",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//     },
//     {
//         img: PersonImg,
//         name: "Ronak Soni 3",
//         role: "CEO",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//     },
//     {
//         img: PersonImg,
//         name: "Ronak Soni 4",
//         role: "CEO",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//     },
//     {
//         img: PersonImg,
//         name: "Ronak Soni 5",
//         role: "CEO",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//     },
//     {
//         img: PersonImg,
//         name: "Ronak Soni 6",
//         role: "CEO",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//     },
//     {
//         img: PersonImg,
//         name: "Ronak Soni 7",
//         role: "CEO",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//     },
//     {
//         img: PersonImg,
//         name: "Ronak Soni 8",
//         role: "CEO",
//         desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
//     },
// ];

const AboutUs = () => {
    const navigateTo = useNavigate();
    let slidesPerView = 9;
    if (window.innerWidth <= 350) {
        slidesPerView = 3;
    } else if (window.innerWidth <= 450) {
        slidesPerView = 3.5;
    } else if (window.innerWidth <= 550) {
        slidesPerView = 4;
    } else if (window.innerWidth <= 650) {
        slidesPerView = 5;
    } else if (window.innerWidth <= 750) {
        slidesPerView = 5.5;
    } else if (window.innerWidth <= 850) {
        slidesPerView = 6;
    } else if (window.innerWidth <= 950) {
        slidesPerView = 7;
    } else if (window.innerWidth <= 1050) {
        slidesPerView = 8;
    }

    const [activeSlide, setActiveSlide] = useState(0);
    const containerRef = useRef();
    const caseStudyTitleRef = useRef();
    const [itemsPerScreen, setItemsPerScreen] = useState(3);
    const caseStudyRef = useRef();
    const getPaginationItems = () => {
        const array = new Array(
            Math.ceil(caseStudies?.length / itemsPerScreen)
        ).fill(0);
        const items = array.map((item, indx) => (
            <span
                key={indx}
                onClick={() => setActiveSlide(indx)}
                className={`pagination-item ${
                    activeSlide === indx ? "activated" : ""
                }`}></span>
        ));
        return items;
    };

    const [currentPeople, setCurrentPeople] = useState(0);
    const [currentPeopleDetails, setCurrentPeopleDetails] = useState();
    const [previousPeopleDetails, setPreviousPeopleDetails] = useState();
    const [nextPeopleDetails, setNextPeopleDetails] = useState();
    const [caseStudies, setCaseStudies] = useState();
    const [clientDetails, setClientDetails] = useState();
    const [aboutContent, setAboutContent] = useState();

    const { isLoading: about_loading } = useQuery(
        ["about"],
        () => GetAboutContent(),
        {
            // staleTime: STALE_TIME,
            cacheTime: CACHE_TIME,
            onSuccess: (data) => {
                // console.log(data);
                if (data.data?.status[0].Error === "False") {
                    setAboutContent(data.data.value);
                }
            },
            onError: (err) => {
                // console.log(err, "ERROR");
                if (err.message) {
                    toast.error(err.message);
                } else {
                    toast.error("Something went wrong");
                }
            },
        }
    );
    useQuery(["get-client"], () => GetClientDetails(), {
        // staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onSuccess: (data) => {
            // console.log(data);
            if (data?.data?.status[0].Error === "False") {
                setClientDetails(data.data.value);
            }
        },
        onError: (err) => {
            // console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });
    useQuery(["case-studies"], () => GetAllCaseStudies(), {
        // staleTime: STALE_TIME,
        cacheTime: CACHE_TIME,
        onSuccess: (data) => {
            // console.log(data);
            if (data.data?.status[0].Error === "False") {
                setCaseStudies(data.data.value);
            }
        },
        onError: (err) => {
            // console.log(err, "ERROR");
            if (err.message) {
                toast.error(err.message);
            } else {
                toast.error("Something went wrong");
            }
        },
    });

    useEffect(() => {
        setPreviousPeopleDetails(
            aboutContent?.peoples[
                ((currentPeople ? currentPeople : 0) -
                    1 +
                    aboutContent?.peoples?.length) %
                    aboutContent?.peoples?.length
            ]
        );
        setCurrentPeopleDetails(
            aboutContent?.peoples[currentPeople ? currentPeople : 0]
        );
        setNextPeopleDetails(
            aboutContent?.peoples[
                ((currentPeople ? currentPeople : 0) + 1) %
                    aboutContent?.peoples?.length
            ]
        );
    }, [currentPeople, aboutContent]);

    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                containerRef.current.scrollLeft =
                    itemsPerScreen *
                        activeSlide *
                        (caseStudyRef?.current?.offsetWidth
                            ? caseStudyRef.current?.offsetWidth
                            : 1) +
                    itemsPerScreen * activeSlide * 25;
            }
        };
        handleScroll();
    }, [activeSlide, itemsPerScreen]);

    useEffect(() => {
        if (window.innerWidth <= 450) {
            setItemsPerScreen(1);
        } else if (window.innerWidth <= 700) {
            setItemsPerScreen(2);
        }

        const scrollValue = parseInt(sessionStorage.getItem("scroll_about"));

        if (caseStudies?.length > 0) {
            window.scrollTo(0, scrollValue ? scrollValue : 0);
        }
    }, [caseStudies]);
    return about_loading ? (
        <></>
    ) : (
        <div className='about-us-main'>
            <div className='heading'>
                <h1>About the company</h1>
            </div>
            <div className='section-1'>
                <img src={domainName + aboutContent?.about_img} alt='' />
                <div className='content'>
                    <p className='about'>{aboutContent?.tag_line}</p>
                    <p>{aboutContent?.about_company}</p>
                    <div className='statistics'>
                        {aboutContent?.statistics?.map((stat, indx) => (
                            <div key={"stat" + indx} className='item'>
                                <span className='number'>{stat.number}+</span>
                                <span className='text'>{stat.title}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {clientDetails && (
                <div className='section-2'>
                    <div className='section-container'>
                        <h1>Clients & companies we work with</h1>
                        <div className='client-container'>
                            <div className='client-logo-swiper'>
                                <Swiper
                                    spaceBetween={24}
                                    centeredSlides={true}
                                    autoplay={{
                                        delay: 500,
                                        disableOnInteraction: false,
                                    }}
                                    loopedSlides={true}
                                    loop={true}
                                    modules={[Autoplay]}
                                    className='mySwiper'
                                    slidesPerView={slidesPerView}
                                    // slidesPerGroup={3}
                                    speed={500}>
                                    {clientDetails?.map((client) => (
                                        <SwiperSlide key={"client" + client.id}>
                                            <div className='client'>
                                                <img
                                                    src={
                                                        domainName +
                                                        "/uploads/" +
                                                        client.company_logo
                                                    }
                                                    alt=''
                                                />
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {aboutContent?.peoples?.length > 0 && (
                <div className='section-3'>
                    <div className='heading'>
                        <h1>Our Leadership</h1>
                        <p>
                            Meet the Visionaries Behind Our Innovative Business
                            Model.
                        </p>
                    </div>
                    <div className='peoples'>
                        {aboutContent?.peoples?.map((people, indx) => (
                            <div className='people' key={"people" + people.id}>
                                <img src={domainName + people.image} alt='' />
                                <div className='person-details'>
                                    <p className='name'>{people.name}</p>
                                    <p className='role'>{people.role}</p>
                                </div>
                                <p className='description'>{people.about}</p>
                                <div className='socials'>
                                    {people.instagram && (
                                        <a
                                            href={people.instagram}
                                            target='__blank'
                                            className='item instagram'>
                                            <Instagram />
                                        </a>
                                    )}
                                    {people.facebook && (
                                        <a
                                            href={people.facebook}
                                            target='__blank'
                                            className='item facebook'>
                                            <Facebook />
                                        </a>
                                    )}
                                    {people.linkedIn && (
                                        <a
                                            href={people.linkedIn}
                                            target='__blank'
                                            className='item linkedIn'>
                                            <LinkedIn />
                                        </a>
                                    )}
                                    {people.twitter && (
                                        <a
                                            href={people.twitter}
                                            target='__blank'
                                            className='item twitter'>
                                            <Twitter />
                                        </a>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='peoples-mobile'>
                        {previousPeopleDetails && (
                            <div className='people'>
                                {aboutContent?.peoples?.length > 1 && (
                                    <>
                                        <img
                                            src={
                                                domainName +
                                                previousPeopleDetails?.image
                                            }
                                            alt=''
                                        />
                                        <div className='person-details'>
                                            <p className='name'>
                                                {previousPeopleDetails?.name}
                                            </p>
                                            <p className='role'>
                                                {previousPeopleDetails?.role}
                                            </p>
                                        </div>
                                        <p className='description'>
                                            {previousPeopleDetails?.about}
                                        </p>
                                        <div className='socials'>
                                            {previousPeopleDetails?.instagram && (
                                                <a
                                                    href={
                                                        previousPeopleDetails?.instagram
                                                    }
                                                    target='__blank'
                                                    className='item instagram'>
                                                    <Instagram />
                                                </a>
                                            )}
                                            {previousPeopleDetails?.facebook && (
                                                <a
                                                    href={
                                                        previousPeopleDetails?.facebook
                                                    }
                                                    target='__blank'
                                                    className='item facebook'>
                                                    <Facebook />
                                                </a>
                                            )}
                                            {previousPeopleDetails?.linkedIn && (
                                                <a
                                                    href={
                                                        previousPeopleDetails?.linkedIn
                                                    }
                                                    target='__blank'
                                                    className='item linkedIn'>
                                                    <LinkedIn />
                                                </a>
                                            )}
                                            {previousPeopleDetails?.twitter && (
                                                <a
                                                    href={
                                                        previousPeopleDetails?.twitter
                                                    }
                                                    target='__blank'
                                                    className='item twitter'>
                                                    <Twitter />
                                                </a>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                        {currentPeopleDetails && (
                            <div className='people current-people'>
                                <img
                                    src={
                                        domainName + currentPeopleDetails?.image
                                    }
                                    alt=''
                                />
                                <div className='person-details'>
                                    <p className='name'>
                                        {currentPeopleDetails?.name}
                                    </p>
                                    <p className='role'>
                                        {currentPeopleDetails?.role}
                                    </p>
                                </div>
                                <p className='description'>
                                    {currentPeopleDetails?.about}
                                </p>
                                <div className='socials'>
                                    {currentPeopleDetails?.instagram && (
                                        <a
                                            href={
                                                currentPeopleDetails?.instagram
                                            }
                                            target='__blank'
                                            className='item instagram'>
                                            <Instagram />
                                        </a>
                                    )}
                                    {currentPeopleDetails?.facebook && (
                                        <a
                                            href={
                                                currentPeopleDetails?.facebook
                                            }
                                            target='__blank'
                                            className='item facebook'>
                                            <Facebook />
                                        </a>
                                    )}
                                    {currentPeopleDetails?.linkedIn && (
                                        <a
                                            href={
                                                currentPeopleDetails?.linkedIn
                                            }
                                            target='__blank'
                                            className='item linkedIn'>
                                            <LinkedIn />
                                        </a>
                                    )}
                                    {currentPeopleDetails?.twitter && (
                                        <a
                                            href={currentPeopleDetails?.twitter}
                                            target='__blank'
                                            className='item twitter'>
                                            <Twitter />
                                        </a>
                                    )}
                                </div>
                            </div>
                        )}
                        {nextPeopleDetails && (
                            <div className='people'>
                                {aboutContent?.peoples?.length > 1 && <>
                                    <img
                                        src={
                                            domainName +
                                            nextPeopleDetails?.image
                                        }
                                        alt=''
                                    />
                                    <div className='person-details'>
                                        <p className='name'>
                                            {nextPeopleDetails?.name}
                                        </p>
                                        <p className='role'>
                                            {nextPeopleDetails?.role}
                                        </p>
                                    </div>
                                    <p className='description'>
                                        {nextPeopleDetails?.desc}
                                    </p>
                                    <div className='socials'>
                                        {nextPeopleDetails?.instagram && (
                                            <a
                                                href={
                                                    nextPeopleDetails?.instagram
                                                }
                                                target='__blank'
                                                className='item instagram'>
                                                <Instagram />
                                            </a>
                                        )}
                                        {nextPeopleDetails?.facebook && (
                                            <a
                                                href={
                                                    nextPeopleDetails?.facebook
                                                }
                                                target='__blank'
                                                className='item facebook'>
                                                <Facebook />
                                            </a>
                                        )}
                                        {nextPeopleDetails?.linkedIn && (
                                            <a
                                                href={
                                                    nextPeopleDetails?.linkedIn
                                                }
                                                target='__blank'
                                                className='item linkedIn'>
                                                <LinkedIn />
                                            </a>
                                        )}
                                        {nextPeopleDetails?.twitter && (
                                            <a
                                                href={
                                                    nextPeopleDetails?.twitter
                                                }
                                                target='__blank'
                                                className='item twitter'>
                                                <Twitter />
                                            </a>
                                        )}
                                    </div>
                                </>}
                            </div>
                        )}
                    </div>
                    {aboutContent?.peoples?.length > 1 && <div className='arrows'>
                        <span
                            className='arrow primary-btn'
                            onClick={() =>
                                setCurrentPeople(
                                    (pre) =>
                                        (pre -
                                            1 +
                                            aboutContent?.peoples.length) %
                                        aboutContent?.peoples.length
                                )
                            }>
                            <ArrowBlack />
                        </span>
                        <span
                            className='arrow primary-btn reverse'
                            onClick={() =>
                                setCurrentPeople(
                                    (pre) =>
                                        (pre + 1) % aboutContent?.peoples.length
                                )
                            }>
                            <ArrowBlack />
                        </span>
                    </div>}
                </div>
            )}
            {caseStudies?.length > 0 && (
                <div className='section-4' ref={caseStudyTitleRef}>
                    <div className='section-container'>
                        <h1>Our Case Studies</h1>
                        <p className='description'>
                            Showcasing our success stories and testimonials from
                            our clients across various domains and industries
                        </p>
                        <div
                            className='case-study-container'
                            ref={containerRef}>
                            {caseStudies?.map((study, indx) => (
                                <div
                                    key={"caseStudy" + study.id}
                                    className='case-study'
                                    onClick={() => {
                                        navigateTo(
                                            "/case-study/" + study.id
                                        );
                                        sessionStorage.setItem(
                                            "scroll_about",
                                            window.scrollY
                                        );
                                    }}
                                    ref={indx === 0 ? caseStudyRef : null}>
                                    <img
                                        src={
                                            domainName +
                                            "/uploads/" +
                                            study.image
                                        }
                                        alt=''
                                    />
                                    <p className='title bold'>{study.title}</p>
                                    {/* <p className='about'>{study.tag_line}</p> */}
                                    <p className='read'>
                                        Read More
                                    </p>
                                </div>
                            ))}
                        </div>
                        {caseStudies && (
                            <div className='pagination'>
                                {getPaginationItems()}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AboutUs;
